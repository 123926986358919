import React from "react";
import { CSSTransition } from "react-transition-group";

import "./Symbol.css";

type Props = {
  type?: string;
  value: string;
};

const Symbol = (props: Props) => {
  const [showMessage, setShowMessage] = React.useState(false);
  const [color, setColor] = React.useState("0");
  const [value, setValue] = React.useState("");
  const nodeRef = React.useRef(null);

  React.useEffect(() => {
    setTimeout(() => {
      if (value !== props.value) {
        setValue(props.value);
        setShowMessage(true);
        setColor(String(Math.floor(Math.random() * 4)));
      }
    }, 1);
  }, [props, value]);

  return (
    <CSSTransition
      in={showMessage}
      nodeRef={nodeRef}
      timeout={1500}
      classNames="fade"
      onEntered={() => setShowMessage(false)}
    >
      <div
        ref={nodeRef}
        className={
          "symbol color-" +
          color +
          " symbol-content-" +
          value +
          " symbol-type-" +
          (props.type ?? "-undefined")
        }
      >
        {value}
      </div>
    </CSSTransition>
  );
};

export default Symbol;
