import { Sequence } from "@core/ValueObject/Sequence";
import { ID } from "@core/ValueObject/ID";
import { LevelScore } from "@core/ValueObject/LevelScore";
import { Problem } from "@core/Entity/Problem";
import { ILevel } from "@core/Entity/ILevel";

export enum ProblemProvider {
  FIXED = "problem.provider.fixed",
  RANDOM = "problem.provider.random",
}

export enum LevelVisualizationStrategy {
  WHOLE = "level.visualization.whole",
  PARTIAL = "level.visualization.partial",
}

export enum LevelVisualizationType {
  PLAIN = "level.visualization.plain",
  MEMORIZED = "level.visualization.memorized",
}

export enum LevelPolicyScoringWrongAnswer {
  DECREASE = "level.policy.wrong.answer.decrease.score",
}

export enum LevelPolicySequenceWrongAnswer {
  NOTHING = "level.policy.sequence.wrong.answer.nothing",
  REPEAT = "level.policy.sequence.wrong.answer.repeat",
}

export enum LevelPolicySequenceAnswerCorrect {
  NEVER = "level.policy.sequence.answer.correct.never",
  SHOW = "level.policy.sequence.answer.correct.show",
}

export enum LevelType {
  ADDITION = "level.type.addition",
  ADDITION_WITH_ZERO = "level.type.addition.with.zero",
  SUBSTRACTION = "level.type.substraction",
  SUBSTRACTION_WITH_ZERO = "level.type.substraction.with.zero",
  MIXED_ADDITION_SUBSTRACTION = "level.type.mixed.addition.substraction",
}

export type LevelPolicyScoring = {
  wrongAnswer: LevelPolicyScoringWrongAnswer;
  totalTimeOut?: LevelPolicyScoringWrongAnswer;
  answerPoints: number;
};

export type LevelPolicy = {
  totalTimer?: number;
  stepTimer?: number;
  maxErrorRate?: number;
  scoring: LevelPolicyScoring;
  sequence: {
    wrongAnswer: LevelPolicySequenceWrongAnswer;
    answerCorrecting: LevelPolicySequenceAnswerCorrect;
  };
};

export type LevelSequence = {
  length: number;
  randomize?: boolean;
  visualization: any;
  problems: Problem[];
};

type LevelProps = {
  id: ID;
  dificulty: number;
  icon: string;
  title: string;
  locked: boolean;
  score: LevelScore;
  policy: LevelPolicy;
  sequence: Sequence;
};

export class Level implements ILevel {

  public readonly DEFAULT_ERROR_RATE = 0.5;

  protected props: LevelProps;

  protected mistakes: number;
  protected previousScore: LevelScore;

  protected constructor(props: LevelProps) {
    this.props = props;
    this.mistakes = 0;
    this.previousScore = LevelScore.create({ achieved: LevelScore.NONE });
  }

  get id(): ID {
    return this.props.id;
  }

  get locked(): boolean {
    return this.props.locked;
  }

  get icon(): string {
    return this.props.icon;
  }

  get title(): string {
    return this.props.title;
  }

  get score(): LevelScore {
    return this.props.score;
  }

  get sequence(): Sequence {
    return this.props.sequence;
  }

  get policy(): LevelPolicy {
    return this.props.policy;
  }

  get maxErrorRatePolicy(): number {
    return (this.props.policy.maxErrorRate ?? this.DEFAULT_ERROR_RATE);
  }

  public doScoring() {
    this.previousScore = LevelScore.create({ achieved: this.score.achieved });

    if (
      this.mistakes === 0 &&
      (this.sequence.maxErrorRate ?? -Infinity) < this.maxErrorRatePolicy
    ) {
      this.score.setMax();
    } else if (
      this.mistakes === 0 &&
      (this.sequence.maxErrorRate ?? -Infinity) >= this.maxErrorRatePolicy
    ) {
      this.score.setMiddle();
    } else if (this.mistakes > 0) {
      this.score.setMin();
    } else {
      this.score.reset();
    }
    this.mistakes = 0;
  }

  get isScoreMined(): boolean {
    return this.score.isMin && this.previousScore.isNone;
  }

  get isScoreMaxed(): boolean {
    return this.score.isMax && !this.previousScore.isMax;
  }

  get isScoreMiddled(): boolean {
    return this.score.isMiddle && !this.previousScore.isAtLeastMiddle;
  }

  get isScoreAtLeastMin(): boolean {
    return this.score.isAtLeastMin;
  }

  public onSelect(): void {
    this.mistakes = 0;
  }

  public onProblemMistaken() {
    this.mistakes++;
  }

  public unlock() {
    this.props.locked = false;
    this.mistakes = 0;
  }

  get warning(): boolean {
    return (this.sequence.maxErrorRate?? -Infinity) >= (this.props.policy.maxErrorRate ?? this.DEFAULT_ERROR_RATE);
  }

  public static create(props: LevelProps): Level {
    return new Level(props);
  }
}
