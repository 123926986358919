import { IAnswer, IElement, IProblem, IProblemVisualization } from "@core/Math/IProblem";
import ProblemFactory, { ProblemConfig } from "./Factory";
import { ID } from "@core/ValueObject/ID";

export default class Proxy implements IProblem {
  protected config: ProblemConfig;
  protected instance: IProblem|null;

  constructor(config: ProblemConfig) {
    this.config = config;
    this.instance = null;
  }

  get isSolved(): boolean {
    return this.getInstance().isSolved;
  }

  get visual(): IProblemVisualization {
    return this.getInstance().visual;
  }

  get expression(): IElement[] {
    return this.getInstance().expression;
  }

  get id(): ID {
    return this.getInstance().id;
  }

  public checkAnswer(answer: IAnswer): void {
    return this.getInstance().checkAnswer(answer);
  }

  get answerLength(): number {
    return this.getInstance().answerLength;
  }

  get correctAnswers(): IAnswer {
    return this.getInstance().correctAnswers;
  }

  protected getInstance(): IProblem {
    if (!this.instance) {
      this.instance = ProblemFactory.create(this.config);
    }
    return this.instance;
  }
}
