import React from "react";

import Symbol from "@ui/Component/Symbol";
import CompositeAnswer from "@ui/Component/CompositeAnswer";

import { IElement, IElementType } from "@core/Math/IProblem";

import "./VerticalExpression.css";

type Props = {
  id: string;
  expression: IElement[];
  answerLength?: number;
  correctAnswers?: number[];
  onAnswers: (answers: number[], allEntered: boolean) => void;
};

const VerticalExpression = (props: Props) => {
  let answers: any[] = [];

  const onAnswerValue = (index: number, value?: number) => {
    answers[index] = value ?? false;
    const anyEntered =
      answers.filter((validAnswer) => !isNaN(Number(String(validAnswer)))).length === 0;
    props.onAnswers(answers, anyEntered);
  };

  const getList = () => {
    let index = 0;
    answers = [];

    const size = props.answerLength ?? Math.max(...props.expression.map(o => o.value?.toString().length ?? 1)) + 1;

    return props.expression.map((e: IElement, i) => {
      if (e.type === IElementType.ANSWER) {
        answers.push(false);
        return (
          <CompositeAnswer
            answer={
              props.correctAnswers ? props.correctAnswers[index] : undefined
            }
            index={index++}
            key={props.id + "_answer_" + i}
            id={props.id + "_answer_" + i}
            onValue={onAnswerValue}
            size={size}
          ></CompositeAnswer>
        );
      }

      if (e.type === IElementType.OPERATOR && e.value === "=") {
        return <hr key={props.id + "_symbol_" + i}></hr>;
      }

      if (e.type === IElementType.OPERATOR) {
        return (
          <div className="operator" key={props.id + "_el_symbol_" + i}>
            <Symbol
              type={String(e.type)}
              value={String(e.value)}
              key={props.id + "_symbol_" + i}
            ></Symbol>
          </div>
        );
      }

      return (
        <Symbol
          type={String(e.type)}
          value={String(e.value)}
          key={props.id + "_symbol_" + i}
        ></Symbol>
      );
    });
  };

  return (
    <div className="vertical-expression">
      <div className="expression">{getList()}</div>
    </div>
  );
};

export default VerticalExpression;
