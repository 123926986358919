import React from "react";

import "./BottomBar.css";

type Props = {
  children?: React.ReactNode;
};

const BottomBar = (props: Props) => {
  return (
    <div className="bottombar">
      {props.children}
    </div>
  );
};

export default BottomBar;
