import React from "react";
import { EventRoute, UiRouteEvent } from "@event/Event";
import { useEventDispatch } from "@event/EventContext";

const Navbar = () => {
  const dispatchEvent = useEventDispatch();

  const routeBackHandler = React.useCallback(() => {
    dispatchEvent(UiRouteEvent.ROUTE, EventRoute.ROOT);
  }, [dispatchEvent]);


  return (
    <nav>
      <button onClick={routeBackHandler}>⚪</button>
    </nav>
  );
};

export default Navbar;
