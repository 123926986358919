import { Skill } from "@core/Entity/Skill";
import { AbstractLevels } from "./AbstractLevels";

type SkillsProps = {
  skills: Skill[];
}

export class Skills extends AbstractLevels<Skill> {

  protected constructor(props: SkillsProps) {
    super({levels: props.skills});
  }

  public static create(props: SkillsProps) {
    return new Skills(props);
  }

}
