import React from "react";

import "./ProfileBar.css";

type Props = {
  name: string;
  score: number;
};

const ProfileBar = ({ name, score }: Props) => {
  const [showAdded, setShowAdded] = React.useState(0);
  const [previousScore, setPreviousScore] = React.useState(
    undefined as number | undefined
  );

  React.useEffect(() => {
    if (!name) {
      return;
    }

    if (previousScore !== undefined) {
      setShowAdded(score - previousScore);
      setTimeout(() => setShowAdded(0), 1500);
    }
    setPreviousScore(score);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [score]);

  const formatter = (score: number) => {
    return score < 10000 ? score : Math.floor(score / 1000) + "K";
  };

  return (
    <div className="topbar">
      <div className="name">{name}</div>
      <div className="score">
        {previousScore === undefined ? "" : formatter(score)}
        <div className={"add-score" + (showAdded ? " show" : "")}>
          {showAdded}
        </div>
      </div>
    </div>
  );
};

export default ProfileBar;
