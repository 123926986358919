/* eslint-disable react/react-in-jsx-scope */
import "./Stars.css";

type Props = {
  total: number;
  achieved: number;
  small?: boolean;
  symbol?: number;
};

const starCharCode = 9733;

const Stars = (props: Props) => {

  const getList = (total: number, achieved: number) => {
    return [...Array(total)].map((_e, i) => (
      <div className={i < achieved ? "achieved" : ""} key={i}>
        {String.fromCharCode(props.symbol ?? starCharCode)}
      </div>
    ));
  };

  return <div className={ "stars " + (props.small ? "small" : "") }>{getList(props.total, props.achieved)}</div>;
};

export default Stars;
