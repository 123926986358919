import React from "react";

import * as UI from "@app/DTO/UI/Levels";
import SubLevel from "@ui/Component/SubLevel";

import "./Levels.css";

type Props = {
  eventName: string;
  levels: UI.Level[];
};

const Levels = (props: Props) => {
  const getList = () => {
    return props.levels.map((e) => (
      <SubLevel
        id={e.id}
        eventName={props.eventName}
        title={e.title}
        totalScore={e.score?.total}
        score={e.score?.achieved}
        locked={e.locked}
        warning={e.warning}
        icon={e.icon}
        key={e.id}
      ></SubLevel>
    ));
  };

  return <div className="levels">{getList()}</div>;
};

export default Levels;
