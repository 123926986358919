import { IAnswer, IElement, IProblem, IProblemVisualization } from "@core/Math/IProblem";
import { Problem } from "@core/Entity/Problem";
import { Score } from "@core/ValueObject/Score";

type ProblemsProps = {
  problems: Problem[];
}

export class Problems {

  protected props: ProblemsProps;

  protected currentProblemIndex: number;
  protected currentProblem: Problem;
  protected scoring: Score;

  protected constructor(props: ProblemsProps) {
    this.props = props;
    this.currentProblemIndex = 0;
    this.currentProblem = this.props.problems[this.currentProblemIndex];
    this.scoring = Score.create({
      total: this.props.problems.length,
      achieved: 0,
    });
  }

  public retry(): void {
    this.currentProblemIndex = Math.max(this.currentProblemIndex - 1, 0);
    this.currentProblem = this.props.problems[this.currentProblemIndex]
  }

  get current(): Problem {
    return this.currentProblem;
  }

  get problem(): IProblem {
    return this.currentProblem.problem;
  }

  get get(): Problem[] {
    return this.props.problems;
  }

  get expression(): IElement[] {
    this.currentProblem = this.props.problems[this.currentProblemIndex];
    this.currentProblemIndex ++;
    return this.currentProblem.expression;
  }

  get answerLength(): number {
    return this.currentProblem.answerLength;
  }

  get visual(): IProblemVisualization {
    return this.currentProblem.visual;
  }

  get isCompleted(): boolean {
    return this.currentProblemIndex >= this.props.problems.length;
  }

  get length(): number {
    return this.props.problems.length;
  }

  get score(): Score {
    return this.scoring;
  }

  public checkAnswer(answer: IAnswer) {
    this.currentProblem.checkAnswer(answer);
    if (this.currentProblem.isSolved) {
      this.score.increase();
    } else {
      this.score.decrease();
    }
  }

  get correctAnswers(): IAnswer {
    return this.currentProblem.correctAnswers;
  }

  public static create(props: ProblemsProps) {
    if (props.problems.length === 0) {
      throw new Error("Sequence::problems must have at lease ont");
    }
    return new Problems(props);
  }

}

