import { IAnswer, IElement, IProblemVisualization } from "@core/Math/IProblem";

import { ID } from "@core/ValueObject/ID";
import { Score } from "@core/ValueObject/Score";
import { Age } from "@core/ValueObject/Age";

import { Grade } from "@core/Entity/Grade";
import { Skill } from "@core/Entity/Skill";
import { Level, LevelPolicySequenceAnswerCorrect, LevelPolicySequenceWrongAnswer } from "@core/Entity/Level";
import { Problem } from "@core/Entity/Problem";
import { Profile } from "@core/Entity/Profile";

import { Grades } from "@core/AggregateRoot/Grades";
import { Levels } from "@core/AggregateRoot/Levels";
import { Skills } from "@core/AggregateRoot/Skills";
import { Problems } from "@core/AggregateRoot/Problems";
import { Scoring } from "@core/Service/Scoring";

type GameProps = {
  profile: Profile
  grades: Grade[];
}

export class Game {

  protected userProfile: Profile;
  protected gradesCollection: Grades;
  protected levelsCollection: Levels;
  protected skillsCollection: Skills;
  protected problemsCollection: Problems;
  protected scoring: Scoring

  protected constructor(props: GameProps) {
    this.userProfile = props.profile;
    this.gradesCollection = Grades.create({ grades: props.grades });
    this.skillsCollection = Skills.create({ skills: this.gradesCollection.current.skills });
    this.levelsCollection = Levels.create({ levels: this.skillsCollection.current.levels });
    this.problemsCollection = Problems.create({problems: this.levelsCollection.current.sequence.problems});
    this.scoring = Scoring.create({ game: this, profile: this.userProfile });
  }

  public selectGrade(gradeId: ID|string) {
    this.gradesCollection.select(gradeId);
    this.skillsCollection = Skills.create({ skills: this.gradesCollection.current.skills });
  }

  public selectSkill(skillId: ID|string) {
    this.skillsCollection.select(skillId);
    this.levelsCollection = Levels.create({ levels: this.skillsCollection.current.levels });
  }

  public selectLevel(levelId: ID|string) {
    this.levelsCollection.select(levelId);
    this.problemsCollection = Problems.create({problems: this.levelsCollection.current.sequence.problems});
  }

  get expressionRetry(): IElement[] {
    this.problemsCollection.retry();
    return this.problemsCollection.expression;
  }

  get expression(): IElement[] {
    return this.problemsCollection.expression;
  }

  get visual(): IProblemVisualization {
    return this.problemsCollection.visual;
  }

  get answerLength(): number {
    return this.problemsCollection.answerLength;
  }

  get score(): Score {
    return this.problemsCollection.score;
  }

  public checkAnswer(answer: IAnswer): void {
    this.problemsCollection.checkAnswer(answer);
    if (this.problemsCollection.problem.isSolved) {
      this.scoring.onProblemSolved();
    } else {
      this.level.onProblemMistaken();
    }

    if (this.problemsCollection.isCompleted) {

      this.level.doScoring();
      if (this.level.isScoreAtLeastMin) {
        this.levelsCollection.unlockNext();
      }

      this.skill.doScoring();
      if (this.skill.isScoreMaxed) {
        this.skillsCollection.unlockNext();
      }

      this.grade.doScoring();
      if (this.grade.isScoreMaxed) {
        this.gradesCollection.unlockNext();
      }

      this.scoring.update();
    } else {
      this.skill.doScoring();
      this.grade.doScoring();
    }
  }

  get isLevelCompleted(): boolean {
    return this.problemsCollection.isCompleted;
  }

  get shoudlShowCorrectAnswer(): boolean {
    return (
      !this.problemsCollection.problem.isSolved &&
      this.levelsCollection.current.policy?.sequence?.answerCorrecting === LevelPolicySequenceAnswerCorrect.SHOW
    );
  }

  get shoudlRetryCorrectAnswer(): boolean {
    return (
      !this.problemsCollection.problem.isSolved &&
      this.levelsCollection.current.policy?.sequence?.wrongAnswer === LevelPolicySequenceWrongAnswer.REPEAT
    );
  }

  get correctAnswers(): IAnswer {
    return this.problemsCollection.correctAnswers;
  }

  get grade(): Grade {
    return this.gradesCollection.current;
  }

  get level(): Level {
    return this.levelsCollection.current;
  }

  get skill(): Skill {
    return this.skillsCollection.current;
  }

  get skills(): Skill[] {
    return this.skillsCollection.get;
  }

  get problems(): Problem[] {
    return this.problemsCollection.get;
  }

  get grades(): Grade[] {
    return this.gradesCollection.get;
  }

  get levels() {
    return this.levelsCollection.get;
  }

  get profile(): Profile {
    return this.userProfile;
  }

  public setProfile(profile: any) {
    this.userProfile = Profile.create({
      name: profile.name?? "",
      score: profile.score?? 0,
      age: Age.create({
        age: parseInt(profile.age?? 0),
      }),
    });
  }

  public static create(props: GameProps) {
    return new Game(props);
  }

}
