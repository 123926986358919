import React from "react";

import {
  default as SimpleKeyboard,
  KeyboardReactInterface,
} from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import { UiKeyboardEvent } from "@event/Event";
import { useEvent, useEventDispatch } from "@event/EventContext";

import "./Keyboard.css";

type Props = {
  layout: KeyboardLayout;
  okDisabled?: boolean;
  onCheck?: () => void;
};

export enum KeyboardLayout {
  NUMERIC,
  COMPARE,
}

const Keyboard = (props: Props) => {
  const dispatchEvent = useEventDispatch();

  const keyboard = React.useRef<KeyboardReactInterface>();
  const [inputName, setInputName] = React.useState("default");
  const [inputs, setInputs] = React.useState({});

  React.useEffect(() => {
    setInputs({});
    setInputName("default");
  }, []);

  useEvent(UiKeyboardEvent.FOCUS, (event: { name: string; value: string }) => {
    setInputName(event.name);
  });

  useEvent(
    UiKeyboardEvent.FEEDBACK,
    (event: { name: string; value: string }) => {
      setInputs({
        ...inputs,
        [event.name]: event.value,
      });
      keyboard.current?.setInput(event.value);
    }
  );

  const onChangeAll = (inputs: { [key: string]: string }) => {
    setInputs({ ...inputs });
    dispatchEvent(UiKeyboardEvent.KEY, { ...inputs, focused: inputName });
  };

  const layoutProps = ((layout: KeyboardLayout) => {
    switch (layout) {
      case KeyboardLayout.NUMERIC:
        return {
          theme: "hg-theme-default hg-layout-default",
          layout: {
            default: ["1 2 3", "4 5 6", "7 8 9", "{backspace} 0 {ent}"],
          },
          display: {
            "{numbers}": "123",
            "{ent}": "✔",
            "{backspace}": "✗",
          },
        };
      case KeyboardLayout.COMPARE:
        return {
          theme: "hg-theme-default hg-layout-default",
          layout: {
            default: ["< > =", "{backspace} {ent}"],
          },
          display: {
            "{numbers}": "123",
            "{ent}": "✔",
            "{backspace}": "✗",
          },
        };
    }
  })(props.layout);

  const onKeyPress = React.useCallback((key: string) =>{
    if (props.onCheck && key === "{ent}" && !(props.okDisabled?? false)) {
      props.onCheck();
    }
  }, [props]);

  return (
    <SimpleKeyboard
      keyboardRef={(r) => (keyboard.current = r)}
      inputName={inputName}
      onChangeAll={onChangeAll}
      onKeyPress={onKeyPress}
      disableCaretPositioning={true}
      theme={layoutProps?.theme}
      layout={layoutProps?.layout}
      display={layoutProps?.display}
      useButtonTag={true}
      buttonAttributes={(props.okDisabled?? false) ? [
        {
          attribute: "disabled",
          value: "true",
          buttons: "{ent}"
        },
      ]: []}
    ></SimpleKeyboard>
  );
};

export default Keyboard;
