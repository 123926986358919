import { Levels, FixedProblem } from "@app/config/Type";
import {
  LevelPolicySequenceAnswerCorrect,
  LevelPolicySequenceWrongAnswer,
} from "@core/Entity/Level";

/**
 * @description
 * Числата от 11 до 20. Събиране и изваждане на числата до 20 без преминаване.
 */
const config: Levels = {
  "1-700-1-1": {
    view: {
      title: "11!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "20+?=50", answers: [30] }),
          FixedProblem({ expression: "30+30=?", answers: [60] }),
          FixedProblem({ expression: "30-20=?", answers: [10] }),
          FixedProblem({ expression: "40+20=?", answers: [60] }),
          FixedProblem({ expression: "20+?=60", answers: [40] }),
        ],
      },
    },
  },
  "1-700-1-2": {
    view: {
      title: "12!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "10+?=20", answers: [10] }),
          FixedProblem({ expression: "20-10=?", answers: [10] }),
          FixedProblem({ expression: "30+10=?", answers: [40] }),
          FixedProblem({ expression: "30-?=20", answers: [10] }),
          FixedProblem({ expression: "40+20=?", answers: [60] }),
        ],
      },
    },
  },
  "1-700-1-3": {
    view: {
      title: "13!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "60+?=70", answers: [10] }),
          FixedProblem({ expression: "20+20=?", answers: [40] }),
          FixedProblem({ expression: "30+20=?", answers: [50] }),
          FixedProblem({ expression: "40+20=?", answers: [60] }),
          FixedProblem({ expression: "50+20=?", answers: [70] }),
        ],
      },
    },
  },
  "1-700-1-4": {
    view: {
      title: "13!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "20+?=30", answers: [10] }),
          FixedProblem({ expression: "30-10=?", answers: [20] }),
          FixedProblem({ expression: "10+40=?", answers: [50] }),
          FixedProblem({ expression: "30+?=40", answers: [10] }),
          FixedProblem({ expression: "30-20=?", answers: [10] }),
        ],
      },
    },
  },
  "1-700-1-5": {
    view: {
      title: "13!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "10+?=20", answers: [10] }),
          FixedProblem({ expression: "40+10=?", answers: [50] }),
          FixedProblem({ expression: "50+10=?", answers: [60] }),
          FixedProblem({ expression: "50+?=60", answers: [10] }),
          FixedProblem({ expression: "20+30=?", answers: [50] }),
        ],
      },
    },
  },
};

export default config;
