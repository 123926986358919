import React from "react";
import { useLocation } from "react-router-dom";

import { AppLevelsEvent } from "@event/Event";
import { useEvent, useEventDispatch } from "@event/EventContext";

import * as UI from "@app/DTO/UI/Levels";
import { default as LevelsComponent } from "@ui/Component/Levels";

const Levels = () => {
  const [levels, setLevels] = React.useState<UI.Level[]>([]);
  const dispatchEvent = useEventDispatch();

  const { state } = useLocation();

  useEvent(AppLevelsEvent.DATA, (event: { levels: UI.Level[]; grade: any }) => {
    setLevels(event.levels);
  });

  React.useEffect(() => {
    dispatchEvent(AppLevelsEvent.INIT, state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className="levels-screen">
      <LevelsComponent eventName={AppLevelsEvent.SELECT} levels={levels} />
    </div>
  );
};

export default Levels;
