/* eslint-disable @typescript-eslint/no-var-requires */
import {
  IAnswer,
  IElement,
  IElementType,
  IProblem,
  IVisualType,
  IProblemVisualization,
} from "@core/Math/IProblem";
import { ID } from "@core/ValueObject/ID";
const matchAll = require("string.prototype.matchall");
matchAll.shim();

export type FixedConfig = {
  expression: string;
  answers: number[];
  orderedAnswers?: boolean;
  visual?: IProblemVisualization;
};

export default class Fixed implements IProblem {
  protected props: FixedConfig;
  protected solved: boolean | undefined;

  constructor(props: FixedConfig) {
    this.props = props;
    this.solved = undefined;
  }

  get isSolved(): boolean {
    return this.solved ?? false;
  }

  get id(): ID {
    return ID.create(this.props.expression);
  }

  get visual(): IProblemVisualization {
    return (
      this.props.visual ??
      ({
        type: IVisualType.EXPRESSION,
      } as IProblemVisualization)
    );
  }

  get expression(): IElement[] {
    this.solved = undefined;
    const matches = Array.from(
      this.props.expression.matchAll(
        /(?<OPERAND_INT>\d+(?!\.))|(?<OPERAND_FLOAT>\d+\.\d+)|(?<OPERATOR>[-+=x*/:])|(?<ANSWER>[?])|(?<ERROR>[^-.+=*/:?0-9])/g
      )
    );

    const elements: IElement[] = [];
    matches.forEach((element) => {
      if (element.groups?.OPERAND_FLOAT) {
        elements.push({
          value: parseFloat(element[0]),
          type: IElementType.OPERAND,
        });
      } else if (element.groups?.OPERAND_INT) {
        elements.push({
          value: parseInt(element[0]),
          type: IElementType.OPERAND,
        });
      } else if (element.groups?.OPERATOR) {
        elements.push({
          value: element[0],
          type: IElementType.OPERATOR,
        });
      } else if (element.groups?.ANSWER) {
        elements.push({
          type: IElementType.ANSWER,
        });
      } else {
        throw Error(`Unknown symbol for <${element[0]}>`);
      }
    });
    return elements;
  }

  get answerLength(): number {
    return String(
      this.props.answers.reduce((a, b) => Math.max(a, b), -Infinity)
    ).length;
  }

  get correctAnswers(): IAnswer {
    this.solved = undefined;
    return this.props.answers;
  }

  public checkAnswer(answer: IAnswer): void {
    let correct = false;
    if (this.props.orderedAnswers ?? true) {
      correct =
        answer.length === this.props.answers.length &&
        answer.toString() === this.props.answers.toString();
    } else {
      correct =
        answer.length === this.props.answers.length &&
        [...answer].sort().toString() ===
          [...this.props.answers].sort().toString(); // nosonar
    }
    this.solved = (this.solved ?? false) ? false : correct;
  }
}
