import { ID } from "@core/ValueObject/ID";
import { LevelScore } from "@core/ValueObject/LevelScore";
import { Skill } from "@core/Entity/Skill";
import { ILevel } from "@core/Entity/ILevel";

type GradeProps = {
  id: ID;
  title: string;
  icon: string;
  age: number;
  locked: boolean;
  score: LevelScore;
  skills: Skill[];
}

export class Grade implements ILevel {

  protected props: GradeProps;

  protected previousScore: LevelScore;

  protected constructor(props: GradeProps) {
    this.props = {...props};
    this.previousScore = LevelScore.create({ achieved: LevelScore.NONE });
  }

  public doScoring(): void {
    this.previousScore = LevelScore.create({ achieved: this.score.achieved });
    const minSkillScore = this.skills.map((value) => value.score.achieved).reduce((a, b) => Math.min(a, b), +Infinity);
    if (minSkillScore < LevelScore.MIN) {
      this.score.reset();
    } else if (minSkillScore < LevelScore.MIDDLE) {
      this.score.setMin();
    } else if (minSkillScore < LevelScore.MAX) {
      this.score.setMiddle();
    } else {
      this.score.setMax();
    }
  }

  get isScoreMaxed(): boolean {
    return this.score.isMax && !this.previousScore.isMax;
  }

  get isScoreMiddled(): boolean {
    return this.score.isMiddle && !this.previousScore.isAtLeastMiddle;
  }

  public unlock() {
    this.props.locked = false;
  }

  get id(): ID {
    return this.props.id;
  }

  get locked(): boolean {
    return this.props.locked;
  }

  get icon(): string {
    return this.props.icon;
  }

  get score(): LevelScore {
    return this.props.score;
  }

  get age(): number {
    return this.props.age;
  }

  get skills(): Skill[] {
    return this.props.skills;
  }

  public static create(props: GradeProps): Grade {
    if (!props.skills.length) {
      throw Error("Grade must have at least one skill");
    }
    return new Grade(props);
  }

}

