import { ID } from "@core/ValueObject/ID";
import { LevelScore } from "@core/ValueObject/LevelScore";
import { Level } from "@core/Entity/Level";
import { ILevel } from "@core/Entity/ILevel";

type SkillProps = {
  id: ID;
  title: string;
  icon: string;
  dificulty: number;
  locked: boolean;
  score: LevelScore;
  levels: Level[];
}

export class Skill implements ILevel {

  protected props: SkillProps;

  protected previousScore: LevelScore;

  protected constructor(props: SkillProps) {
    this.props = {...props};
    this.previousScore = LevelScore.create({ achieved: LevelScore.NONE });
  }

  public doScoring(): void {
    this.previousScore = LevelScore.create({ achieved: this.score.achieved });

    const minLevelScore = this.levels.map((value) => value.score.achieved).reduce((a, b) => Math.min(a, b), +Infinity);
    if (minLevelScore < LevelScore.MIN) {
      this.score.reset();
    } else if (minLevelScore < LevelScore.MIDDLE) {
      this.score.setMin();
    } else if (minLevelScore < LevelScore.MAX) {
      this.score.setMiddle();
    } else {
      this.score.setMax();
    }
  }

  get isScoreMaxed(): boolean {
    return this.score.isMax && !this.previousScore.isMax;
  }

  get isScoreMiddled(): boolean {
    return this.score.isMiddle && !this.previousScore.isAtLeastMiddle;
  }

  public unlock() {
    this.props.locked = false;
  }

  get id(): ID {
    return this.props.id;
  }

  get locked(): boolean {
    return this.props.locked;
  }

  get icon(): string {
    return this.props.icon;
  }

  get score(): LevelScore {
    return this.props.score;
  }

  get levels(): Level[] {
    return this.props.levels;
  }

  public static create(props: SkillProps): Skill {
    if (!props.levels.length) {
      throw Error("Skill must have at least one level");
    }
    return new Skill(props);
  }

}

