import React from "react";

import "./Answer.css";
import { useEvent, useEventDispatch } from "@event/EventContext";
import { useFocus } from "@ui/Hooks/Input";
import { UiKeyboardEvent } from "@event/Event";

type Props = {
  id: string;
  index: number;
  size?: number;
  hint?: boolean;
  answer?: number | null;
  onValue: (index: number, value?: number) => void;
  autoFocus?: boolean;
};

const Answer = (props: Props) => {
  const dispatchEvent = useEventDispatch();

  const [inputRef, setInputFocus] = useFocus();
  const [value, setValue] = React.useState("");
  const [answer, setAnswer] = React.useState(
    undefined as undefined | number | null
  );
  const [showMistake, setShowMistake] = React.useState(false);

  const onFocus = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      dispatchEvent(UiKeyboardEvent.FOCUS, {
        name: event.target.id,
        value: event.target.value,
      });
    },
    [dispatchEvent]
  );

  useEvent(UiKeyboardEvent.KEY, (inputs: { [key: string]: string }) => {
    const name = props.id + "-answer-" + props.index;

    if (inputs[name] === undefined) {
      props.onValue(props.index, undefined);
      return;
    }

    if (inputs[name].length <= (props.size ?? 1)) {
      setValue(inputs[name]);
      dispatchEvent(UiKeyboardEvent.FEEDBACK, { name: name, value: inputs[name] });
      props.onValue(props.index, parseInt(inputs[name]));
    } else {
      dispatchEvent(UiKeyboardEvent.FEEDBACK, { name: name, value: value });
    }

    if (inputs.focused === name) {
      setTimeout(() => setInputFocus(), 100);
    }
  });

  React.useEffect(() => {
    const name = props.id + "-answer-" + props.index;

    if (props.answer !== undefined) {
      setShowMistake(false);
      setShowMistake(true);
      setAnswer(props.answer);
    } else {
      setShowMistake(false);
      if (answer !== undefined) {
        setValue("");
        dispatchEvent(UiKeyboardEvent.FEEDBACK, { name: name, value: "" });
        if (!props.index && (props.autoFocus ?? true)) {
          setInputFocus();
          setTimeout(() => setInputFocus(), 100);
        }
        props.onValue(props.index, undefined);
      }
      setAnswer(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, answer]);

  React.useEffect(() => {
    const name = props.id + "-answer-" + props.index;
    dispatchEvent(UiKeyboardEvent.FEEDBACK, { name: name, value: "" });
    return () => {
      dispatchEvent(UiKeyboardEvent.FEEDBACK, { name: name, value: "" });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={"answer" + " size_" + (props.size ?? 1)}>
      <div className={"mistake" + (showMistake ? " show" : "")}>
        <div className="correctAnswer">{answer === null ? "•" : answer}</div>
        <input
          className="number"
          id={props.id + "-answer-" + props.index}
          autoFocus={!props.index}
          autoComplete="off"
          onFocus={onFocus}
          value={value}
          onChange={(e) => e.preventDefault()}
          inputMode="none"
          placeholder={"?".repeat(props.hint ? props.size ?? 1 : 1)}
          size={props.size ?? 1}
          step={1}
          maxLength={props.size ?? 1}
          ref={inputRef}
        ></input>
      </div>
    </div>
  );
};

export default Answer;
