import { Profile } from "@core/Entity/Profile";
import { Game } from "@core/Service/Game";

type ScoringProps = {
  profile: Profile;
  game: Game;
};

export class Scoring {
  protected userProfile: Profile;
  protected game: Game;

  protected readonly SCORE_PROBLEM_SOLVED = 10;
  protected readonly SCORE_PROBLEM_SOLVED_AGAIN = 2;

  protected readonly SCORE_LEVEL_MIN = 10;
  protected readonly SCORE_LEVEL_MIDDLE = 100;
  protected readonly SCORE_LEVEL_MAXED = 300;

  protected readonly SCORE_SKILL_MIDDLE = 1000;
  protected readonly SCORE_SKILL_MAXED = 3000;

  protected readonly SCORE_GRADE_MIDDLE = 10000;
  protected readonly SCORE_GRADE_MAXED = 30000;

  protected constructor(props: ScoringProps) {
    this.userProfile = props.profile;
    this.game = props.game;
  }

  protected dificulty(): number {
    if (this.game.grade.age === this.userProfile.age) {
      return 1;
    }
    if (this.game.grade.age > this.userProfile.age) {
      return 1 + Math.pow(this.game.grade.age - this.userProfile.age, 1.2) / 2;
    }
    return 0.5 / (this.userProfile.age - this.game.grade.age);
  }

  public onProblemSolved() {
    if (this.game.level.isScoreMaxed) {
      this.userProfile.scoring(this.dificulty() * this.SCORE_PROBLEM_SOLVED_AGAIN);
    } else if (this.game.level.isScoreMiddled) {
      this.userProfile.scoring(this.dificulty() * this.SCORE_PROBLEM_SOLVED_AGAIN);
    } else {
      this.userProfile.scoring(this.dificulty() * this.SCORE_PROBLEM_SOLVED);
    }
  }

  public update() {
    if (this.game.level.isScoreMaxed) {
      this.userProfile.scoring(this.dificulty() * this.SCORE_LEVEL_MAXED);
    } else if (this.game.level.isScoreMiddled) {
      this.userProfile.scoring(this.dificulty() * this.SCORE_LEVEL_MIDDLE);
    }

    if (this.game.skill.isScoreMaxed) {
      this.userProfile.scoring(this.dificulty() * this.SCORE_SKILL_MAXED);
    } else if (this.game.skill.isScoreMiddled) {
      this.userProfile.scoring(this.dificulty() * this.SCORE_SKILL_MIDDLE);
    }

    if (this.game.grade.isScoreMaxed) {
      this.userProfile.scoring(this.dificulty() * this.SCORE_SKILL_MAXED);
    } else if (this.game.grade.isScoreMiddled) {
      this.userProfile.scoring(this.dificulty() * this.SCORE_SKILL_MIDDLE);
    }
  }

  public static create(props: ScoringProps) {
    return new Scoring(props);
  }
}
