import { Levels, FixedProblem } from "@app/config/Type";
import {
  LevelPolicySequenceAnswerCorrect,
  LevelPolicySequenceWrongAnswer,
} from "@core/Entity/Level";

/**
 * @description
 * Числата от 11 до 20. Събиране и изваждане на числата до 20 без преминаване.
 */
const config: Levels = {
  "1-1100-1-1": {
    view: {
      title: "11!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "60+?=90", answers: [30] }),
          FixedProblem({ expression: "40-?=20", answers: [20] }),
          FixedProblem({ expression: "80+20=?", answers: [100] }),
          FixedProblem({ expression: "100-?=50", answers: [50] }),
          FixedProblem({ expression: "30+60=?", answers: [90] }),
        ],
      },
    },
  },
  "1-1100-1-2": {
    view: {
      title: "12!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "30+50=?", answers: [80] }),
          FixedProblem({ expression: "80-40=?", answers: [40] }),
          FixedProblem({ expression: "60+30=?", answers: [90] }),
          FixedProblem({ expression: "70-50=?", answers: [20] }),
          FixedProblem({ expression: "70+30=?", answers: [100] }),
        ],
      },
    },
  },
  "1-1100-1-3": {
    view: {
      title: "13!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "40+60=?", answers: [100] }),
          FixedProblem({ expression: "30+70=?", answers: [100] }),
          FixedProblem({ expression: "60+40=?", answers: [100] }),
          FixedProblem({ expression: "50+?=100", answers: [50] }),
          FixedProblem({ expression: "20+70=?", answers: [90] }),
        ],
      },
    },
  },
  "1-1100-1-4": {
    view: {
      title: "13!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "40+50=?", answers: [90] }),
          FixedProblem({ expression: "50+?=80", answers: [30] }),
          FixedProblem({ expression: "90-40=?", answers: [50] }),
          FixedProblem({ expression: "70+20=?", answers: [90] }),
          FixedProblem({ expression: "20+?=70", answers: [50] }),
        ],
      },
    },
  },
  "1-1100-1-5": {
    view: {
      title: "13!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "60+?=100", answers: [40] }),
          FixedProblem({ expression: "60-30=?", answers: [30] }),
          FixedProblem({ expression: "80+10=?", answers: [90] }),
          FixedProblem({ expression: "90+10=?", answers: [100] }),
          FixedProblem({ expression: "100-20=?", answers: [80] }),
        ],
      },
    },
  },
};

export default config;
