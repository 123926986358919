import React from "react";

const useFocus = (): [ React.RefObject<HTMLInputElement>, () => void ] => {
  const htmlElRef = React.useRef<HTMLInputElement>(null);
  const setFocus = (): void => {
    setTimeout(() => htmlElRef.current && htmlElRef.current.focus(), 10);
  };

  return [htmlElRef, setFocus];
};

export { useFocus };
