/* eslint-disable @typescript-eslint/no-var-requires */
const semverDiff = require("semver/functions/diff");
const semverValid = require("semver/functions/valid");

import config from "@app/config/game";

import storage from "@util/Storage";
import * as Storage from "@app/DTO/Storage";

import { Grade as GradeEntity } from "@core/Entity/Grade";
import { Skill as SkillEntity } from "@core/Entity/Skill";
import { Level as LevelEntity } from "@core/Entity/Level";
import { Problem } from "@core/Entity/Problem";
import { Profile } from "@core/Entity/Profile";

import { ID } from "@core/ValueObject/ID";
import { LevelScore } from "@core/ValueObject/LevelScore";
import { Sequence } from "@core/ValueObject/Sequence";
import { Age } from "@core/ValueObject/Age";

import { ProblemConfig } from "@core/Math/Factory";
import Proxy from "@core/Math/Proxy";

import { Game as GameService } from "@core/Service/Game";

class Game {
  public versioning() {
    const savedGame = storage.get() as Storage.Game;
    if (config.version !== savedGame.version) {
      if (semverValid(savedGame.version)) {
        if (semverDiff(config.version, savedGame.version) === "major") {
          storage.reset();
        }
      } else {
        storage.reset();
      }
    }
    storage.set({ version: config.version ?? "" });
  }

  get profile(): Profile {
    const savedGame = storage.get() as Storage.Game;
    return savedGame?.profile
      ? Profile.create({
          age: Age.create({
            age: savedGame?.profile.age ?? 0,
            date: savedGame?.profile.dateCreated,
          }),
          score: savedGame?.profile.score ?? 0,
          name: savedGame?.profile.name ?? "",
        })
      : Profile.create({
          age: Age.create({
            age: 0,
          }),
          score: 0,
          name: "",
        });
  }

  get grades(): GradeEntity[] {
    const savedGame = storage.get() as Storage.Game;
    const savedGrades: Storage.Grades = savedGame?.grades ?? {};

    return Object.keys(config.grades).map((id: string) => {
      return GradeEntity.create({
        id: ID.create(id),
        age: config.grades[id].age,
        locked: savedGrades[id] ? savedGrades[id].locked ?? true : true,
        title: config.grades[id].view.title,
        icon: config.grades[id].view.icon,
        score: LevelScore.create({
          achieved: savedGrades[id] ? savedGrades[id].score : 0,
        }),
        skills: this.getSkillsForGrade(ID.create(id)),
      });
    });
  }

  protected getSkillsForGrade(gradeId: ID): SkillEntity[] {
    const grade = config.grades[gradeId.value];

    const savedGame = storage.get() as Storage.Game;
    const savedGrades: Storage.Grades = savedGame?.grades ?? {};
    const savedSkills: Storage.Skills = savedGrades[gradeId.value]
      ? savedGame.grades[gradeId.value].skills
      : {};

    return Object.keys(grade.skills).map((id: string) => {
      return SkillEntity.create({
        id: ID.create(id),
        dificulty: 1,
        locked: savedSkills[id] ? savedSkills[id].locked ?? true : true,
        title: grade.skills[id].view.title,
        icon: grade.skills[id].view.icon,
        score: LevelScore.create({
          achieved: savedSkills[id] ? savedSkills[id].score : 0,
        }),
        levels: this.getLevelsForSkill(gradeId, ID.create(id)),
      });
    });
  }

  protected getLevelsForSkill(gradeId: ID, skillId: ID): LevelEntity[] {
    const levels =
      config.grades[gradeId.value].skills[skillId.value].levels ?? {};

    const savedGame = storage.get() as Storage.Game;
    const savedGrades: Storage.Grades = savedGame?.grades ?? {};
    const savedSkills: Storage.Skills = savedGrades[gradeId.value]
      ? savedGame.grades[gradeId.value].skills
      : {};
    const savedLevels: Storage.Levels = savedSkills[skillId.value]
      ? savedSkills[skillId.value].levels
      : {};

    return Object.keys(levels).map((id: string) => {
      const savedLevel: Storage.Level = savedLevels[id] ?? {};
      const savedProblems: Storage.Problems = savedLevel.problems ?? {};

      return LevelEntity.create({
        id: ID.create(id),
        dificulty: 1,
        locked: savedLevels[id] ? savedLevel.locked ?? true : true,
        title: levels[id].view.title,
        icon: levels[id].view.icon,
        score: LevelScore.create({
          achieved: savedLevels[id] ? savedLevel.score : 0,
        }),
        policy: levels[id].model.policy,
        sequence: Sequence.create({
          length: levels[id].model.sequence.length ?? 1,
          problems: levels[id].model.sequence.problems.map(
            (config: ProblemConfig) => {
              const problem = new Proxy(config);
              return Problem.create({
                total: savedProblems[problem.id.value]?.total ?? 0,
                solved: savedProblems[problem.id.value]?.solved ?? 0,
                problem: problem,
              });
            }
          ),
          visualization: levels[id].model.sequence.visualization,
          randomize: levels[id].model.sequence.randomize,
        }),
      });
    });
  }

  public save(game: GameService) {
    const savedGame = storage.get();
    savedGame.grades = savedGame.grades ?? {};
    savedGame.profile = game.profile.toObject;

    game.grades
      .filter((grade) => !grade.locked)
      .forEach((grade: GradeEntity) => {
        const skills: Storage.Skills = {};
        grade.skills
          .filter((skill) => !skill.locked)
          .forEach((skill: SkillEntity) => {
            const levels: Storage.Levels = {};
            skill.levels
              .filter((level) => !level.locked)
              .forEach((level: LevelEntity) => {
                levels[level.id.value] = {
                  id: level.id.value,
                  locked: false,
                  score: level.score.achieved,
                  problems: level.sequence.problems
                    .filter((problem) => problem.total > 0)
                    .reduce((problems: Storage.Problems, problem) => {
                      problems[problem.id.value] = {
                        id: problem.id.value,
                        solved: problem.solved,
                        total: problem.total,
                      };
                      return problems;
                    }, {} as Storage.Problems),
                };
              });

            skills[skill.id.value] = {
              id: skill.id.value,
              locked: false,
              score: skill.score.achieved,
              levels: { ...levels },
            };
          });

        savedGame.grades[grade.id.value] = {
          id: grade.id.value,
          locked: false,
          score: grade.score.achieved,
          skills: { ...skills },
        } as Storage.Grade;
      });

    storage.set(savedGame);
  }
}

export const game = new Game();
