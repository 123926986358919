import React from "react";

import CheckBox from "@ui/Component/CheckBox";

import "./Profile.css";

type Props = {
  name: string;
  age: number;
  onCompleted: (name: string, age: number) => void;
};

const Profile = (props: Props) => {
  const [age, setAge] = React.useState(0);
  const [name, setName] = React.useState("");
  const [validForm, setValidForm] = React.useState(false);


  React.useEffect(() => {
    setAge(props.age);
    setName(props.name);
  }, [props]);

  React.useEffect(() => {
    setValidForm(!!age && !!name);
  }, [age, name]);

  const onCheck = () => {
    props.onCompleted(name, age);
  };

  return (
    <form className="profile">
      <div className="username label">
        <input
          placeholder="name"
          autoFocus
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
        ></input>
      </div>
      <div className="age label">
        <input
          placeholder="age"
          value={age === 0 ? "" : age}
          onChange={(e) => setAge(parseInt(e.target.value))}
          type="number"
          min={1}
          max={20}
          step={1}
        ></input>
      </div>
      <CheckBox onCheck={onCheck} disabled={!validForm}></CheckBox>
    </form>
  );
};

export default Profile;
