import { Problem } from "@core/Entity/Problem";

type SequenceProps = {
  length: number;
  randomize?: boolean;
  visualization: any;
  problems: Problem[];
};

export class Sequence {
  protected props: SequenceProps;

  protected constructor(props: SequenceProps) {
    this.props = props;
  }

  get length(): number {
    return this.props.length;
  }

  get problems(): Problem[] {
    return this.props.problems;
  }

  get maxErrorRate(): number | undefined {
    return this.problems
      .map((problem) => problem.successRate)
      .filter((successRate) => successRate !== undefined)
      .reduce((maxErrorRate, successRate) => {
        if (successRate && (maxErrorRate === undefined)) {
          return 1 - successRate;
        }
        if (successRate === undefined) {
          return undefined;
        }
        return Math.max(maxErrorRate as number, 1 - successRate);
      }, undefined);
  }

  get isRandomized(): boolean {
    return this.props.randomize ?? false;
  }

  public static create(props: SequenceProps): Sequence {
    if (props.length < 1) {
      throw Error("Sequence::length must be greater than zero");
    }
    if (props.problems.length < 1) {
      throw Error("Sequence::problems must have at least one");
    }
    return new Sequence(props);
  }
}
