import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer"

import Symbol from "@ui/Component/Symbol";
import Answer from "@ui/Component/Answer";

import { IElement, IElementType } from "@core/Math/IProblem";

import "./MemoryExpression.css";

type Props = {
  id: string;
  expression: IElement[];
  answerLength?: number;
  correctAnswers?: number[];
  delay: number;
  onAnswers: (answers: number[], allEntered: boolean) => void;
};

const MemoryExpression = (props: Props) => {
  const [index, setIndex] = React.useState(-1);
  const [timerVisible, setTimerVisible] = React.useState(true);

  let answers: any[] = [];

  const onAnswerValue = React.useCallback((index: number, value?: number) => {
    answers[index] = value ?? false;
    const allEntered =
      answers.filter((validAnswer) => isNaN(Number(String(validAnswer)))).length === 0;
    props.onAnswers(answers, allEntered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  React.useEffect(() => {
    if (props.correctAnswers) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      answers = [];
      setTimeout(() => setIndex(0), props.delay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getElement = (elementIndex: number) => {
    if (index < 0) {
      return;
    }

    const element = props.expression[elementIndex];
    if (element.type === IElementType.ANSWER) {
      answers.push(false);
      return (
        <Answer
          answer={props.correctAnswers ? props.correctAnswers[0] : undefined}
          index={0}
          key={props.id + "_answer_" + elementIndex}
          id={props.id + "_answer_" + elementIndex}
          onValue={onAnswerValue}
          size={props.answerLength ?? 1}
        ></Answer>
      );
    }
    return (
      <Symbol
        type={String(element.type)}
        value={String(element.value)}
        key={props.id + "_symbol_" + elementIndex}
      ></Symbol>
    );
  };

  React.useEffect(() => {
    if (index < 0) {
      return;
    }
    if (index < props.expression.length - 1) {
      const delay =
        props.expression[index].type === IElementType.OPERAND
          ? props.delay
          : Math.min(1000, props.delay / 2);

      setTimeout(() => {
        setIndex(index + 1);
      }, delay);
    }
  }, [props, index]);

  const onPreviewTimerComplete = () => {
    setTimerVisible(false);
    setIndex(0);
  };

  return (
    <div className="memory-expression" key={props.id}>
      <div className={ "timer" + (timerVisible ? "" : " hidden")}>
        <CountdownCircleTimer
          isPlaying={true}
          duration={2}
          size={80}
          colors={['#004777', '#A30000']}
          colorsTime={[1, 0]}
          onComplete={onPreviewTimerComplete}
        ></CountdownCircleTimer>
      </div>
      <Symbol value=""></Symbol>
      {getElement(index)}
    </div>
  );
};

export default MemoryExpression;
