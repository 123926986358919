import { Levels, FixedProblem } from "@app/config/Type";
import {
  LevelPolicySequenceAnswerCorrect,
  LevelPolicySequenceWrongAnswer,
} from "@core/Entity/Level";

/**
 * @description
 * Числата от 11 до 20. Събиране и изваждане на числата до 20 без преминаване.
 */
const config: Levels = {
  "1-900-1-1": {
    view: {
      title: "11!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "30+?=80", answers: [50] }),
          FixedProblem({ expression: "40+40=?", answers: [80] }),
          FixedProblem({ expression: "50+40=?", answers: [90] }),
          FixedProblem({ expression: "50+50=?", answers: [100] }),
          FixedProblem({ expression: "50+?=60", answers: [10] }),
        ],
      },
    },
  },
  "1-900-1-2": {
    view: {
      title: "12!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "30+30=?", answers: [60] }),
          FixedProblem({ expression: "40+?=60", answers: [20] }),
          FixedProblem({ expression: "50-30=?", answers: [20] }),
          FixedProblem({ expression: "50+?=70", answers: [20] }),
          FixedProblem({ expression: "60-10=?", answers: [50] }),
        ],
      },
    },
  },
  "1-900-1-3": {
    view: {
      title: "13!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "70+?=80", answers: [10] }),
          FixedProblem({ expression: "70-20=?", answers: [50] }),
          FixedProblem({ expression: "80+?=90", answers: [10] }),
          FixedProblem({ expression: "50-20=?", answers: [30] }),
          FixedProblem({ expression: "90+?=100", answers: [10] }),
        ],
      },
    },
  },
  "1-900-1-4": {
    view: {
      title: "13!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "20+50=?", answers: [70] }),
          FixedProblem({ expression: "20+?=60", answers: [40] }),
          FixedProblem({ expression: "40+40=?", answers: [80] }),
          FixedProblem({ expression: "40+60=?", answers: [100] }),
          FixedProblem({ expression: "60+?=100", answers: [40] }),
        ],
      },
    },
  },
  "1-900-1-5": {
    view: {
      title: "13!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "40+?=50", answers: [10] }),
          FixedProblem({ expression: "40+30=?", answers: [70] }),
          FixedProblem({ expression: "70-40=?", answers: [30] }),
          FixedProblem({ expression: "70+10=?", answers: [80] }),
          FixedProblem({ expression: "70-30=?", answers: [40] }),
        ],
      },
    },
  },
};

export default config;
