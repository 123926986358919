import React from "react";

import Keyboard, { KeyboardLayout } from "@ui/Component/Keyboard";

import Stars from "@ui/Component/Stars";
import ProblemFactory from "@ui/Component/Problem/Factory";

import {
  IElement,
  IProblemVisualization,
  IVisualType,
} from "@core/Math/IProblem";

import "./Level.css";

type Props = {
  scoreAchieved: number;
  scoreTotal: number;
  id: string;
  answerLength?: number;
  correctAnswers?: (number | null)[];
  expression: IElement[];
  visual: IProblemVisualization;
  onAnswers: (answers: number[]) => void;
};

const Level = (props: Props) => {
  const allAnswers = React.useRef([] as number[]);
  const [validAnswers, setValidAnswers] = React.useState(false);

  React.useEffect(() => {
    setValidAnswers(false);
    allAnswers.current = [];
  }, [props]);

  const onAnswers = React.useCallback(
    (answers: number[], allEntered: boolean) => {
      setValidAnswers(allEntered);
      if (allEntered) {
        allAnswers.current = [...answers];
      } else {
        allAnswers.current = [];
      }
    },
    [allAnswers]
  );

  const onConfirmAnswers = React.useCallback(() => {
    props.onAnswers(allAnswers.current);
  }, [props, allAnswers]);

  return (
    <div className="level">
      <Stars total={props.scoreTotal} achieved={props.scoreAchieved}></Stars>
      <ProblemFactory
        key={"problem-" + props.id}
        id={props.id}
        problem={{
          expression: props.expression,
          onAnswers: onAnswers,
          answerLength: props.answerLength,
          correctAnswers: props.correctAnswers,
        }}
        visualization={
          props.visual ??
          ({
            type: IVisualType.EXPRESSION,
          } as IProblemVisualization)
        }
      ></ProblemFactory>
      <div className="keyboard">
        <Keyboard
          layout={props.visual?.keyboard?? KeyboardLayout.NUMERIC}
          onCheck={onConfirmAnswers}
          okDisabled={!validAnswers}
        ></Keyboard>
      </div>
    </div>
  );
};

export default Level;
