import { Score } from "@core/type/Score";

export enum ProblemProvider {
  FIXED = "problem.provider.fixed",
  RANDOM = "problem.provider.random",
}

export enum LevelVisualizationType {
  PLAIN = "level.visualization.plain",
  MEMORIZED = "level.visualization.memorized",
}

export enum LevelPolicyScoringWrongAnswer {
  DECREASE = "level.policy.wrong.answer.decrease.score",
}

export enum LevelPolicySequenceWrongAnswer {
  NOTHING = "level.policy.sequence.wrong.answer.nothing",
  REPEAT = "level.policy.sequence.wrong.answer.repeat",
}

export type LevelPolicyScoring = {
  wrongAnswer: LevelPolicyScoringWrongAnswer,
  totalTimeOut?: LevelPolicyScoringWrongAnswer,
  answerPoints: number,
}

export type LevelPolicy = {
  totalTimer?: number,
  stepTimer?: number,
  scoring: LevelPolicyScoring,
  sequence: {
    wrongAnswer: LevelPolicySequenceWrongAnswer,
  },
};

export type LevelProblem = {
  type: ProblemProvider
  config?: any,
};

export type LevelVisualization = {
  type: LevelVisualizationType,
  params?: any,
};

export type LevelSequence = {
  length: number,
  randomize?: boolean,
  visualization: LevelVisualization,
  problems: LevelProblem[]
};

export type Level = {
  id: string;
  dificulty: number,
  locked: boolean,
  score: Score,
  policy: LevelPolicy,
  sequence: LevelSequence,
  icon: string;
  title?: string;
};

export type Levels = { [key: string]: Level };
