import { Age } from "@core/ValueObject/Age";

type ProfileProps = {
  name: string;
  age: Age;
  score: number;
};

export class Profile {

  protected props: ProfileProps;

  protected constructor(props: ProfileProps) {
    this.props = props;
  }

  get age(): number {
    return this.props.age.age;
  }

  get dateCreated(): Date {
    return this.props.age.date;
  }

  get name(): string {
    return this.props.name;
  }

  get score(): number {
    return this.props.score;
  }

  public scoring(score: number) {
    this.props.score += Math.ceil(score);
  }

  get isCompleted(): boolean {
    return this.props.name !== "";
  }

  get toObject(): object {
    return {
      age: this.age,
      name: this.name,
      score: this.score,
      dateCreated: this.dateCreated,
    }
  }

  public static create(props: ProfileProps): Profile {
    if (props.score < 0) {
      throw Error("Profile::score must be non negative");
    }
    return new Profile(props);
  }

}
