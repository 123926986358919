import React from "react";

import Symbol from "@ui/Component/Symbol";
import Answer from "@ui/Component/Answer";

import { IElement, IElementType } from "@core/Math/IProblem";

import "./Expression.css";

type Props = {
  id: string;
  expression: IElement[];
  answerLength?: number;
  correctAnswers?: (number|null)[];
  onAnswers: (answers: number[], allEntered: boolean) => void;
};

const Expression = (props: Props) => {
  let answers: any[] = [];

  const onAnswerValue = (index: number, value?: number) => {
    answers[index] = value ?? false;
    const allEntered =
      answers.filter((validAnswer) => isNaN(Number(String(validAnswer)))).length === 0;
    props.onAnswers(answers, allEntered);
  };

  const getList = () => {
    let index = 0;
    answers = [];
    return props.expression.map((e: IElement, i) => {
      if (e.type === IElementType.ANSWER) {
        answers.push(false);
        return (
          <Answer
            answer={
              props.correctAnswers ? props.correctAnswers[index] : undefined
            }
            index={index++}
            key={props.id + "_answer_" + i}
            id={props.id + "_answer_" + i}
            onValue={onAnswerValue}
            size={props.answerLength ?? 1}
          ></Answer>
        );
      }

      return (
        <Symbol
          type={String(e.type)}
          value={String(e.value)}
          key={props.id + "_symbol_" + i}
        ></Symbol>
      );
    });
  };

  return (
    <div className="expression">
      <Symbol value=""></Symbol>
      {getList()}
    </div>
  );
};

export default Expression;
