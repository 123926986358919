import { Skills } from "@app/config/Type";

import { default as Skills_100_Levels } from "@app/config/levels/grade-1/100";
import { default as Skills_200_Levels } from "@app/config/levels/grade-1/200";
import { default as Skills_300_Levels } from "@app/config/levels/grade-1/300";
import { default as Skills_400_Levels } from "@app/config/levels/grade-1/400";
import { default as Skills_500_Levels } from "@app/config/levels/grade-1/500";
import { default as Skills_600_Levels } from "@app/config/levels/grade-1/600";
import { default as Skills_700_Levels } from "@app/config/levels/grade-1/700";
import { default as Skills_800_Levels } from "@app/config/levels/grade-1/800";
import { default as Skills_900_Levels } from "@app/config/levels/grade-1/900";
import { default as Skills_1000_Levels } from "@app/config/levels/grade-1/1000";
import { default as Skills_1100_Levels } from "@app/config/levels/grade-1/1100";

export const Grade_I_Skills: Skills = {
    "1-100": {
        view: {
        icon: "bug",
      },
      levels: Skills_100_Levels,
    },
    "1-200": {
        view: {
        icon: "turtle",
      },
      levels: Skills_200_Levels,
    },
    "1-300": {
        view: {
        icon: "ant",
      },
      levels: Skills_300_Levels,
    },
    "1-400": {
        view: {
        icon: "lady-bug",
      },
      levels: Skills_400_Levels,
    },
    "1-500": {
        view: {
        icon: "chick",
      },
      levels: Skills_500_Levels,
    },
    "1-600": {
        view: {
        icon: "penguin",
      },
      levels: Skills_600_Levels,
    },
    "1-700": {
        view: {
        icon: "rabbit",
      },
      levels: Skills_700_Levels,
    },
    "1-800": {
        view: {
        icon: "whale",
      },
      levels: Skills_800_Levels,
    },
    "1-900": {
        view: {
        icon: "tiger",
      },
      levels: Skills_900_Levels,
    },
    "1-1000": {
        view: {
        icon: "horse",
      },
      levels: Skills_1000_Levels,
    },
    "1-1100": {
        view: {
        icon: "leopard",
      },
      levels: Skills_1100_Levels,
    },
};
