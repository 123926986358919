export enum AppStageEvent {
  INIT = "app.stage.init",
  DONE = "app.stage.done",
}

export enum AppViewEvent {
  SCORE = "app.view.score",
}

export enum AppGradesEvent {
  INIT = "app.grades.init",
  DATA = "app.grades.data",
  SELECT = "app.grades.select",
}

export enum AppSkillsEvent {
  INIT = "app.skills.init",
  DATA = "app.skills.data",
  SELECT = "app.skills.select",
}

export enum AppLevelsEvent {
  INIT = "app.levels.init",
  SELECT = "app.levels.select",
  DATA = "app.levels.data",
}

export enum AppLevelEvent {
  INIT = "app.level.init",
  EXPRESSION = "app.level.expression",
  SCORE = "app.level.score",
  ANSWER = "app.level.answer",
  MISTAKE = "app.level.mistake",
  DONE = "app.level.done",
}

export enum AppProfileEvent {
  COMPLETED = "app.profile.completed",
}

export enum UiRouteEvent {
  ROUTE = "ui.route.route",
}

export enum UiKeyboardEvent {
  FOCUS = "ui.keyboard.focus",
  BLUR = "ui.keyboard.blur",
  FEEDBACK = "ui.keyboard.feedback",
  KEY = "ui.keyboard.key",
}

export type RoutingEvent = {
  route: string;
  data?: any;
}

export enum EventRoute {
  ROOT = "/",
  SKILLS = "skills",
  LEVELS = "levels",
  LEVEL = "level",
  GRADES = "grades",
  GRADE = "grade",
  PROFILE = "profile",
}
