import { Grade } from "@core/Entity/Grade";
import { AbstractLevels } from "./AbstractLevels";

type GradesProps = {
  grades: Grade[];
}

export class Grades extends AbstractLevels<Grade> {

  protected constructor(props: GradesProps) {
    super({levels: props.grades});
  }

  public static create(props: GradesProps) {
    return new Grades(props);
  }

}
