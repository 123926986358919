import { ProblemProvider } from "@core/Entity/Level";
import { FixedConfig } from "@core/Math/Fixed";
import { IVisualType, IProblemVisualization } from "@core/Math/IProblem";
import { KeyboardLayout } from "@ui/Component/Keyboard";

export const FixedProblem = ({
  expression,
  answers,
  visual,
  keyboard,
  orderedAnswers,
}: {
  expression: string;
  answers: number[];
  visual?: IVisualType;
  keyboard?: KeyboardLayout;
  orderedAnswers?: boolean;
}) => {
  return {
    type: ProblemProvider.FIXED,
    config: {
      expression: expression,
      answers: answers,
      orderedAnswers: orderedAnswers ?? false,
      visual: {
        type: visual ?? IVisualType.EXPRESSION,
        keyboard: keyboard ?? KeyboardLayout.NUMERIC,
      } as IProblemVisualization,
    } as FixedConfig,
  };
};

export const FixedProblemMemory = ({
  expression,
  answers,
  delay
}: {
  expression: string;
  answers: number[];
  delay?: number;
}) => {
  return {
    type: ProblemProvider.FIXED,
    config: {
      expression: expression,
      answers: answers,
      orderedAnswers: false,
      visual: {
        type: IVisualType.MEMORY,
        params: {
          delay: delay ?? 1200,
        },
      } as IProblemVisualization,
    } as FixedConfig,
  };
};

type Level = {
  view: any;
  model: {
    policy: any,
    sequence: {
      visualization?: any,
      length?: number,
      randomize: boolean,
      problems: any[],
    },
  },
};

export type Levels = { [key: string]: Level };

type Skill = {
  view: any;
  levels: Levels;
};

export type Skills = { [key: string]: Skill };

type Grade = {
  age: number;
  view: any;
  skills: Skills;
};

type Grades = { [key: string]: Grade };

export type Config = { version?: string; grades: Grades };
