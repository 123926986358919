import { Levels, FixedProblem } from "@app/config/Type";
import { LevelPolicySequenceAnswerCorrect, LevelPolicySequenceWrongAnswer } from "@core/Entity/Level";

/**
 * @description
 * Числата от 11 до 20. Събиране и изваждане на числата до 20 без преминаване.
 */
const config: Levels = {
  "1-500-1-1": {
    view: {
      title: "11!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "9+1=?", answers: [10] }),
          FixedProblem({ expression: "9+2=?", answers: [11] }),
          FixedProblem({ expression: "2+9=?", answers: [11] }),
          FixedProblem({ expression: "8+2=?", answers: [10] }),
          FixedProblem({ expression: "8+3=?", answers: [11] }),
          FixedProblem({ expression: "3+8=?", answers: [11] }),
        ],
      },
    },
  },
  "1-500-1-2": {
    view: {
      title: "12!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "9+2=?", answers: [11] }),
          FixedProblem({ expression: "9+3=?", answers: [12] }),
          FixedProblem({ expression: "3+9=?", answers: [12] }),
          FixedProblem({ expression: "6+6=?", answers: [12] }),
          FixedProblem({ expression: "8+3=?", answers: [11] }),
          FixedProblem({ expression: "8+4=?", answers: [12] }),
        ],
      },
    },
  },
  "1-500-1-3": {
    view: {
      title: "13!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "9+3=?", answers: [12] }),
          FixedProblem({ expression: "9+4=?", answers: [13] }),
          FixedProblem({ expression: "4+9=?", answers: [13] }),
          FixedProblem({ expression: "3+?=13", answers: [10] }),
          FixedProblem({ expression: "4+9=?", answers: [13] }),
          FixedProblem({ expression: "8+4=?", answers: [12] }),
        ],
      },
    },
  },
  "1-500-1-4": {
    view: {
      title: "13!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "9+4=?", answers: [13] }),
          FixedProblem({ expression: "9+5=?", answers: [14] }),
          FixedProblem({ expression: "6+7=?", answers: [13] }),
          FixedProblem({ expression: "6+8=?", answers: [14] }),
          FixedProblem({ expression: "7+?=14", answers: [7] }),
        ],
      },
    },
  },
};

export default config;
