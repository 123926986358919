import { Events } from "@event/EventContext";
import {
  AppStageEvent,
  UiRouteEvent,
  EventRoute,
  AppGradesEvent,
  AppLevelsEvent,
  AppLevelEvent,
  AppProfileEvent,
  AppViewEvent,
  AppSkillsEvent,
} from "@event/Event";

import * as GradeRepository from "@app/Repository/Game";

import * as Service from "@core/Service/Game";

class Game {
  protected game: Service.Game;

  constructor() {
    GradeRepository.game.versioning();

    this.game = Service.Game.create({
      grades: GradeRepository.game.grades,
      profile: GradeRepository.game.profile,
    });
  }

  public start() {
    if (this.game.profile.isCompleted) {
      Events.emit(AppViewEvent.SCORE, this.game.profile, 100);
    } else {
      Events.emit(UiRouteEvent.ROUTE, { route: EventRoute.PROFILE, data: this.game.profile.toObject }, 100);
    }

    this.profileEvents();
    this.gradeEvents();
    this.skillEvents();
    this.levelEvents();
    this.problemEvents();
  }

  public profileEvents() {
    Events.on(AppStageEvent.INIT, () => {
      if (this.game.profile.isCompleted) {
        Events.emit(AppViewEvent.SCORE, this.game.profile);
        Events.emit(UiRouteEvent.ROUTE, EventRoute.GRADES);
      } else {
        Events.emit(UiRouteEvent.ROUTE, { route: EventRoute.PROFILE, data: this.game.profile.toObject });
      }
    }).on(AppProfileEvent.COMPLETED, (profile: object) => {
      this.game.setProfile(profile);
      GradeRepository.game.save(this.game);
      Events.emit(AppStageEvent.INIT);
    });
  }

  public gradeEvents() {
    Events.on(AppGradesEvent.INIT, () => {
      Events.emit(AppGradesEvent.DATA, {
        grades: this.game.grades,
      });
    });
    Events.on(AppGradesEvent.SELECT, (grade: any) => {
      this.game.selectGrade(grade.id);
      Events.emit(UiRouteEvent.ROUTE, {
        route: EventRoute.SKILLS,
        data: this.game.grade,
      });
    });
  }

  public skillEvents() {
    Events.on(AppSkillsEvent.INIT, () => {
      Events.emit(AppSkillsEvent.DATA, {
        skills: this.game.skills,
        grade: this.game.grade,
      });
    }).on(AppSkillsEvent.SELECT, (skill: any) => {
      this.game.selectSkill(skill.id);
      Events.emit(UiRouteEvent.ROUTE, {
        route: EventRoute.LEVELS,
        data: this.game.skill,
      });
    });
  }

  public levelEvents() {
    Events.on(AppLevelsEvent.INIT, () => {
      Events.emit(AppLevelsEvent.DATA, {
        levels: this.game.levels,
        skill: this.game.skill,
      });
    }).on(AppLevelsEvent.SELECT, (level: any) => {
      this.game.selectLevel(level.id);
      Events.emit(UiRouteEvent.ROUTE, {
        route: EventRoute.LEVEL,
        data: this.game.level,
      });
    });
  }

  public problemEvents() {
    Events.on(AppLevelEvent.INIT, () => {
      Events.emit(AppLevelEvent.EXPRESSION, {
        expression: this.game.expression,
        visual: this.game.visual,
        answerLength: this.game.answerLength,
        score: this.game.score,
      });
    }).on(AppLevelEvent.ANSWER, (answer) => {

      this.game.checkAnswer(answer);
      Events.emit(AppViewEvent.SCORE, {
        score: this.game.profile.score,
      })
      GradeRepository.game.save(this.game);

      if (this.game.shoudlShowCorrectAnswer) {
        Events.emit(AppLevelEvent.MISTAKE, {
          correctAnswers: this.game.correctAnswers
        });
      } else if (this.game.shoudlRetryCorrectAnswer) {
        Events.emit(AppLevelEvent.MISTAKE, {
          correctAnswers: []
        }).emit(AppLevelEvent.SCORE, {
          score: this.game.score,
        });
      } else if (this.game.grade.isScoreMaxed) {
        Events.emit(UiRouteEvent.ROUTE, EventRoute.GRADES);
      } else if (this.game.skill.isScoreMaxed) {
        Events.emit(UiRouteEvent.ROUTE, EventRoute.SKILLS);
      } else if (this.game.isLevelCompleted) {
        Events.emit(UiRouteEvent.ROUTE, {
          route: EventRoute.LEVELS,
          data: this.game.grade,
        }, 1000).emit(AppLevelEvent.SCORE, {
          score: this.game.score,
        });
      } else {
        Events.emit(AppLevelEvent.EXPRESSION, {
          expression: this.game.expression,
          visual: this.game.visual,
          answerLength: this.game.answerLength,
          score: this.game.score,
        });
      }
    });
  }
}

export default Game;
