import { Level } from "@core/Entity/Level";
import { Sequence } from "@core/ValueObject/Sequence";
import { AbstractLevels } from "./AbstractLevels";

type LevelsProps = {
  levels: Level[];
}

export class Levels extends AbstractLevels<Level> {

  get sequence(): Sequence {
    return this.currentLevel.sequence;
  }

  get isScoreMaxed(): boolean {
    const nonMaxLevel = this.props.levels.find((level) => {
      if (level.locked) {
        return true;
      }
      if (!level.score.isMax) {
        return true;
      }
      return false;
    });

    return !nonMaxLevel;
  }

  public static create(props: LevelsProps) {
    return new Levels(props);
  }

}
