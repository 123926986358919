import React from "react";
import { useLocation } from "react-router-dom";

import { AppProfileEvent } from "@event/Event";
import { useEventDispatch } from "@event/EventContext";

import {default as ProfileForm } from "@ui/Component/Profile";

const Profile = () => {
  const [age, setAge] = React.useState(0);
  const [name, setName] = React.useState("");
  const dispatchEvent = useEventDispatch();

  const { state } = useLocation();

  React.useEffect(() => {
    setName(state.name);
    setAge(state.age);
  }, [state]);

  const onConfirm = (name: string, age: number) => {
    dispatchEvent(AppProfileEvent.COMPLETED, {
      age: age,
      name: name,
    });
  };

  return (
    <ProfileForm age={age} name={name} onCompleted={onConfirm}></ProfileForm>
  );
};

export default Profile;
