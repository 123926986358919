import { IProblem } from "@core/Math/IProblem";
import { ProblemProvider } from "@core/type/Level";
import Fixed from "@core/Math/Fixed";

export type ProblemConfig = {
  type: ProblemProvider;
  config: any;
};

class Factory {
  create(config: ProblemConfig): IProblem {
    if (!config.type) {
      throw Error("Problem::Factory::create type not provided");
    }
    if (!config.config) {
      throw Error("Problem::Factory::create config not provided");
    }

    switch (config.type) {
      case ProblemProvider.FIXED:
        return new Fixed(config.config);
      case ProblemProvider.RANDOM:
      default:
        throw Error(`Unknown problem creator <${config.type}>`);
    }
  }
}

const ProblemFactory = new Factory();
export default ProblemFactory;
