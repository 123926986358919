import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import { EventEmitterContext } from "@event/EventContext";

import "./index.css";

import Game from "@app/Game";
import App from "@ui/App";

const game = new Game();

/**
 * @ https://react.dev/reference/react/StrictMode
 */
const Root = () => {
  return (
    <BrowserRouter>
      <EventEmitterContext>
        <App />
      </EventEmitterContext>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<Root />);

game.start();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
