import React from "react";
import { DNA as DnaSpinner } from "react-loader-spinner";

import { AppStageEvent } from "@event/Event";
import { useEventDispatch } from "@event/EventContext";

const Landing = () => {
  const dispatchEvent = useEventDispatch();

  React.useEffect(() => {
    setTimeout(() => {
      dispatchEvent(AppStageEvent.INIT, {});
    }, 400);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DnaSpinner
      visible={true}
      height="80"
      width="80"
      ariaLabel="dna-loading"
      wrapperStyle={{ margin: "25%" }}
      wrapperClass="dna-wrapper"
    />
  );
};

export default Landing;
