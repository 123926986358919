import { Levels, FixedProblem, FixedProblemMemory } from "@app/config/Type";
import { LevelPolicySequenceAnswerCorrect, LevelPolicySequenceWrongAnswer } from "@core/Entity/Level";

/**
 * @description
 * Естествените числа от 1 до 5 и 0. Събиране и изваждане с числата до 5.
 */
const config: Levels = {
  "1-100-1-1": {
    view: {
      title: "1+1",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "1+1=?", answers: [2] }),
          FixedProblem({ expression: "2+1=?", answers: [3] }),
          FixedProblem({ expression: "1+2=?", answers: [3] }),
          FixedProblemMemory({ expression: "1+1=?", answers: [2], delay: 2000 }),
        ],
      },
    },
  },
  "1-100-1-2": {
    view: {
      title: "1+?",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        length: 10,
        randomize: false,
        problems: [
          FixedProblem({ expression: "1+1=?", answers: [2] }),
          FixedProblem({ expression: "2+1=?", answers: [3] }),
          FixedProblem({ expression: "1+?=2", answers: [1] }),
          FixedProblem({ expression: "2+?=3", answers: [1] }),
          FixedProblemMemory({ expression: "1+2=?", answers: [3], delay: 2000 }),
        ],
      },
    },
  },
  "1-100-1-3": {
    view: {
      title: "0!?!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        length: 10,
        randomize: false,
        problems: [
          FixedProblem({ expression: "2+0=?", answers: [2] }),
          FixedProblem({ expression: "0+1=?", answers: [1] }),
          FixedProblem({ expression: "5+0=?", answers: [5] }),
          FixedProblem({ expression: "0+4=?", answers: [4] }),
          FixedProblem({ expression: "4+0=?", answers: [4] }),
          FixedProblemMemory({ expression: "1+1+0+1=?", answers: [3], delay: 2000, }),
        ],
      },
    },
  },
  "1-100-1-31": {
    view: {
      title: "++",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        length: 10,
        randomize: false,
        problems: [
          FixedProblem({ expression: "2+1=?", answers: [3] }),
          FixedProblem({ expression: "3+1=?", answers: [4] }),
          FixedProblem({ expression: "5+0=?", answers: [5] }),
          FixedProblem({ expression: "1+4=?", answers: [5] }),
          FixedProblem({ expression: "4+1=?", answers: [5] }),
          FixedProblem({ expression: "1+1=?", answers: [2] }),
          FixedProblem({ expression: "1+0=?", answers: [1] }),
        ],
      },
    },
  },
  "1-100-1-4": {
    view: {
      title: "?-1",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        length: 10,
        randomize: false,
        problems: [
          FixedProblem({ expression: "2-1=?", answers: [1] }),
          FixedProblem({ expression: "4-1=?", answers: [3] }),
          FixedProblem({ expression: "3-1=?", answers: [2] }),
          FixedProblem({ expression: "5-1=?", answers: [4] }),
          FixedProblem({ expression: "1-1=?", answers: [0] }),
          FixedProblemMemory({ expression: "2+2-1=?", answers: [3], delay: 2000 }),
          FixedProblemMemory({ expression: "1+2-1=?", answers: [2], delay: 2000 }),
        ],
      },
    },
  },
  "1-100-1-5": {
    view: {
      title: "2-?",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        length: 10,
        randomize: false,
        problems: [
          FixedProblem({ expression: "2-?=1", answers: [1] }),
          FixedProblem({ expression: "4-2=?", answers: [2] }),
          FixedProblem({ expression: "3-2=?", answers: [1] }),
          FixedProblem({ expression: "5-2=?", answers: [3] }),
          FixedProblem({ expression: "2-2=?", answers: [0] }),
          FixedProblemMemory({ expression: "1+2-2=?", answers: [1], delay: 2000, }),
          FixedProblemMemory({ expression: "2+2-2=?", answers: [2], delay: 2000, }),
        ],
      },
    },
  },
  "1-100-1-6": {
    view: {
      title: "3-?",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        length: 10,
        randomize: false,
        problems: [
          FixedProblem({ expression: "5-3=?", answers: [2] }),
          FixedProblem({ expression: "4-3=?", answers: [1] }),
          FixedProblem({ expression: "3-3=?", answers: [0] }),
          FixedProblem({ expression: "3-2=?", answers: [1] }),
          FixedProblem({ expression: "5-?=2", answers: [3] }),
          FixedProblemMemory({ expression: "5-3+2=?", answers: [4], delay: 2000, }),
          FixedProblemMemory({ expression: "5-3-1=?", answers: [1], delay: 2000, }),
        ],
      },
    },
  },
  "1-100-1-7": {
    view: {
      title: "",
      icon: "brain",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        length: 10,
        randomize: false,
        problems: [
          FixedProblem({ expression: "5-4=?", answers: [1] }),
          FixedProblem({ expression: "5-1=?", answers: [4] }),
          FixedProblem({ expression: "5-?=1", answers: [4] }),
          FixedProblem({ expression: "5-5=?", answers: [0] }),
          FixedProblem({ expression: "5-?=2", answers: [3] }),
          FixedProblemMemory({ expression: "5-4-1=?", answers: [0], delay: 2000, } ),
        ],
      },
    },
  },
  "1-100-1-8": {
    view: {
      title: "",
      icon: "brain",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        length: 10,
        randomize: false,
        problems: [
          FixedProblem({ expression: "4-2=?", answers: [2] }),
          FixedProblem({ expression: "5-1=?", answers: [4] }),
          FixedProblem({ expression: "3-2=?", answers: [1] }),
          FixedProblem({ expression: "5-2=?", answers: [3] }),
          FixedProblem({ expression: "4-1=?", answers: [3] }),
          FixedProblemMemory({ expression: "4-2-1+1+1=?", answers: [3], delay: 2000, }),
          FixedProblemMemory({ expression: "5-3+1-2+4=?", answers: [5], delay: 2000, }),
        ],
      },
    },
  },
};

export default config;
