type AgeProps = {
  age: number;
  date?: Date | string;
};

export class Age {
  protected initialAge: number;
  protected initialDate: Date;
  protected readonly SECONDS_IN_YEAR = 31556926;

  protected constructor(age: number, date: Date) {
    this.initialAge = age;
    this.initialDate = date;
  }

  get age(): number {
    return Math.floor(
      this.initialAge +
        ((new Date()).getTime() - this.initialDate.getTime() ) /
          (this.SECONDS_IN_YEAR * 1000)
    );
  }

  get date(): Date {
    return this.initialDate;
  }

  public static create(props: AgeProps): Age {
    if (props.age < 0) {
      throw Error("Age::age must be positive");
    }
    if (!props.date) {
      return new Age(props.age, new Date());
    }

    let date: Date | undefined;
    if (typeof props.date === "string") {
      date = new Date(props.date);
    } else if (props.date instanceof Date) {
      date = props.date;
    } else {
      throw Error("Age::date is invalid");
    }

    const now = new Date();
    if (now.getTime() < date.getTime()) {
      throw Error("Age::date is in the future");
    }

    return new Age(props.age, date);
  }
}
