import React from "react";
import { useLocation } from "react-router-dom";

import { AppGradesEvent } from "@event/Event";
import { useEvent, useEventDispatch } from "@event/EventContext";

import { Level as LevelDTO } from "@app/DTO/UI/Levels";
import { default as GradesComponent } from "@ui/Component/Levels";

const Grades = () => {
  const [grades, setGrades] = React.useState<LevelDTO[]>([]);
  const dispatchEvent = useEventDispatch();

  const { state } = useLocation();

  useEvent(AppGradesEvent.DATA, (event: { grades: LevelDTO[] }) => {
    setGrades(event.grades);
  });

  React.useEffect(() => {
    dispatchEvent(AppGradesEvent.INIT, state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className="grades-screen">
      <GradesComponent levels={grades} eventName={AppGradesEvent.SELECT} />
    </div>
  );
};

export default Grades;
