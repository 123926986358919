import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { UiRouteEvent, RoutingEvent, AppViewEvent } from "@event/Event";
import { useEvent } from "@event/EventContext";

import ProfileBar from "./Component/ProfileBar";
import BottomBar from "./Component/BottomBar";

import Level from "@ui/Screen/Level";
import Levels from "@ui/Screen/Levels";
import Landing from "@ui/Screen/Landing";
import Skills from "@ui/Screen/Skills";
import Grades from "@ui/Screen/Grades";
import Profile from "@ui/Screen/Profile";
import Navbar from "@ui/Navbar";

import "./App.css";

function App() {
  const navigate = useNavigate();
  const [name, setName] = React.useState("");
  const [score, setScore] = React.useState(0);
  const [transitionStage, setTransitionStage] = React.useState("fadeIn");

  const routeEventHandler = React.useCallback(
    (event: RoutingEvent | string) => {
      setTransitionStage("navFadeOut");
      setTimeout(() => {
        if (typeof event === "string") {
          navigate(event, { replace: false });
        } else {
          navigate(event.route, { replace: false, state: event.data });
        }
      }, 500);
    },
    [navigate]
  );

  useEvent(UiRouteEvent.ROUTE, routeEventHandler);
  useEvent(AppViewEvent.SCORE, (event: { score?: number; name?: string }) => {
    setScore(event.score?? score);
    setName(event.name?? name);
  });

  React.useEffect(() => {
    setTimeout(() => {
      setTransitionStage("navFadeIn");
    }, 500);
  }, [transitionStage]);

  return (
    <div className="App">
      <ProfileBar name={name} score={score} />
      <div className={transitionStage + " main"}>
        <Routes>
          <Route index element={<Landing />} />
          <Route path="/level" element={<Level />} />
          <Route path="/levels" element={<Levels />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/grades" element={<Grades />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<Landing />} />
        </Routes>
      </div>
      <BottomBar>
        <Navbar />
      </BottomBar>
    </div>
  );
}

export default App;
