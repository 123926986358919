import { ILevel } from "@core/Entity/ILevel";
import { ID } from "@core/ValueObject/ID";

type AbstractLevelsProps<T extends ILevel> = {
  levels: T[];
}

export class AbstractLevels<T extends ILevel> {

  protected props: AbstractLevelsProps<T>;

  protected currentLevel: T;

  protected constructor(props: AbstractLevelsProps<T>) {
    this.props = props;
    this.currentLevel = this.props.levels[0];
    this.currentLevel.unlock();
  }

  get current(): T {
    return this.currentLevel;
  }

  get get(): T[] {
    return [...this.props.levels] as T[];
  }

  public select(id: ID|string) {
    const level = this.props.levels.find((level) => level.id.equals(id));
    if (!level) {
      throw Error("Could not find level ID " + id.toString());
    }
    if (level.locked) {
      throw Error("Level ID " + id.toString() + " is locked");
    }
    this.currentLevel = level;
  }

  protected getNext(): T|undefined {
    let currentLevelFound = false;
    const currentLevel = this.currentLevel;
    const nextLevel = this.props.levels.find((level) => {
      if (level.id.equals(currentLevel.id)) {
        currentLevelFound = true;
        return false;
      }
      if (currentLevelFound) {
        return true;
      }
      return false;
    });

    return nextLevel;
  }

  public unlockNext() {
    if (this.currentLevel.locked) {
      throw Error("ILevel::unlockNext current level not unlocked");
    }
    const nextLevel = this.getNext();
    if (nextLevel) {
      nextLevel.unlock();
    }
  }

  public isNextUnlocked(): boolean {
    if (this.currentLevel.locked) {
      return false;
    }
    const nextLevel = this.getNext();
    return nextLevel ? !nextLevel.locked : false;
  }

}
