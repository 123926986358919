/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* tslint:disable:no-empty */

import React from "react";
import { EventEmitter } from "events";

const eventEmitter = new EventEmitter();
export default eventEmitter;

type Listener = (...args: any[]) => void;

const emit = (eventName: string, payload?: any, timeout?: number) => {
  setTimeout(() => {
    eventEmitter.emit(eventName, payload);
  }, timeout ? Math.max(1, timeout) : 1);
  return { emit: emit };
}

const on = (eventName: string, listener: Listener) => {
  eventEmitter.addListener(eventName, listener);
  return {on : on};
}

const un = (eventName: string, listener: Listener) => {
  eventEmitter.removeListener(eventName, listener);
  return {un : un};
}

export const Events = {
  emit: emit, on: on, un: un
};

export const EventContext = React.createContext([
  (_event: string, _cb: any) => {}, // subscribe
  (_event: string, _cb: any) => {}, // unsubscribe
  (_event: string, _payload: any) => {}, // dispatch
]);

export const useEventDispatch = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_subscribe, _unsubscribe, dispatch] = React.useContext(EventContext);

  return dispatch;
};

export const useEvent = (event: string, callback: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [subscribe, unsubscribe, _dispatch] = React.useContext(EventContext);

  React.useEffect(() => {
    subscribe(event, callback);
    return () => unsubscribe(event, callback);
  }, [subscribe, unsubscribe, event, callback]);
};

export const EventEmitterContext = ({ children }: { children: any }) => {
  const [_subscribers, dispatch] = React.useReducer(
    (state: any, action: any) => {
      const { type, event } = action;
      switch (type) {
        case "subscribe": {
          const { callback } = action;
          if (event in state) {
            if (state[event].includes(callback)) {
              return state;
            }
            on(event, callback);
            return { ...state, [event]: [...state[event], callback] };
          }
          on(event, callback);
          return { ...state, [event]: [callback] };
        }

        case "unsubscribe": {
          const { callback } = action;
          if (event in state && state[event].includes(callback)) {
            state[event].filter((cb: any) => cb === callback).forEach(() => un(event, callback))
            return {
              ...state,
              [event]: [...state[event].filter((cb: any) => cb !== callback)],
            };
          }
          un(event, callback);
          return state;
        }

        default:
          throw new Error();
      }
    },
    {},
    () => ({})
  );

  const subscribe = React.useCallback(
    (event: string, callback: Listener) => {
      dispatch({ type: "subscribe", event, callback });
    },
    [dispatch]
  );

  const unsubscribe = React.useCallback(
    (event: string, callback: Listener) => {
      dispatch({ type: "unsubscribe", event, callback });
    },
    [dispatch]
  );

  const dispatchEvent = React.useCallback(
    (event: string, payload: any) => {
      emit(event, payload, 1);
    },
    []
  );

  const eventPack = React.useMemo(
    () => [subscribe, unsubscribe, dispatchEvent],
    [subscribe, unsubscribe, dispatchEvent]
  );

  return (
    <EventContext.Provider value={eventPack}>{children}</EventContext.Provider>
  );
};
