import { ID } from "@core/ValueObject/ID";
import { KeyboardLayout } from "@ui/Component/Keyboard";

export enum IElementType {
  OPERAND,
  OPERATOR,
  ANSWER,
  PLACEHOLDER,
}

export enum IOperator {
  EQUALS = '=',
  PLUS = '+',
  MINUS = '-',
  OBELUS = '/',
  TIMES = '*',
}

export enum IVisualType {
  EXPRESSION,
  MEMORY,
  VERTICAL_EXPRESSION,
}

export type IProblemVisualization = {
  type: IVisualType;
  params?: any;
  keyboard?: KeyboardLayout;
};

export type IElement = {
  value?: string|number;
  type: IElementType;
};

export type IAnswer = number[];

export interface IProblem {
  get expression(): IElement[];
  get answerLength(): number;
  get correctAnswers(): number[];
  get isSolved(): boolean;
  get visual(): IProblemVisualization;
  get id(): ID;
  checkAnswer(answers: IAnswer): void;
}
