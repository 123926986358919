import React from "react";

import { IProblemVisualization, IVisualType } from "@core/Math/IProblem";

import Expression from "@ui/Component/Problem/Expression";
import MemoryExpression from "@ui/Component/Problem/MemoryExpression";
import VerticalExpression from "@ui/Component/Problem/VerticalExpression";

type Props = {
  id: string;
  problem: any;
  visualization: IProblemVisualization;
};

const Factory = (props: Props) => {
  return (
    <>
      {(() => {
        switch (props.visualization.type) {
          case IVisualType.EXPRESSION:
            return (
              <Expression
                id={"expression-" + props.id}
                expression={props.problem.expression}
                onAnswers={props.problem.onAnswers}
                answerLength={props.problem.answerLength}
                correctAnswers={props.problem.correctAnswers}
              ></Expression>
            );
          case IVisualType.VERTICAL_EXPRESSION:
            return (
              <VerticalExpression
                id={"expression-" + props.id}
                expression={props.problem.expression}
                onAnswers={props.problem.onAnswers}
                answerLength={props.problem.answerLength}
                correctAnswers={props.problem.correctAnswers}
              ></VerticalExpression>
            );
          case IVisualType.MEMORY:
            return (
              <MemoryExpression
                id={"expression-" + props.id}
                expression={props.problem.expression}
                onAnswers={props.problem.onAnswers}
                answerLength={props.problem.answerLength}
                correctAnswers={props.problem.correctAnswers}
                delay={props.visualization.params?.delay}
              ></MemoryExpression>
            );
          default:
            return null;
        }
      })()}
    </>
  );
};

export default Factory;
