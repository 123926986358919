import React from "react";

import "./CheckBox.css";

type Props = {
  label?: string;
  disabled?: boolean;
  delay?: number;
  onCheck: () => void;
};

const CheckBox = (props: Props) => {

  const [checked, setChecked] = React.useState(false);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.checked && !checked) {
      setChecked(true);
      setTimeout(() => { 
        setChecked(false);
        props.onCheck() 
      }, props.delay ?? 500);
    }
  };

  return (
    <div className="checkbox">
      <input
          id="checkbox-confirm"
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={props.disabled ?? false}
        />
      <label htmlFor="checkbox-confirm">
        {props.label ?? ""}
      </label>
    </div>
  );
};

export default CheckBox;
