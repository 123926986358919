import React from "react";

import { useLocation } from "react-router-dom";

import { AppLevelEvent} from "@event/Event";
import { useEvent, useEventDispatch } from "@event/EventContext";

import {default as LevelComponent} from "@ui/Component/Level";

import {
  IElement,
  IProblemVisualization,
  IVisualType,
} from "@core/Math/IProblem";
import { Score } from "@core/ValueObject/Score";

const uniqueId = (prefix?: string): string => {
  return (prefix ?? "") + "_" + new Date().getTime() + "_" + Math.random() * 10;
};

const Level = () => {
  const [score, setScore] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [id, setId] = React.useState("");
  const [answerLength, setAnswerLength] = React.useState(
    undefined as number | undefined
  );
  const [correctAnswers, setCorrectAnswers] = React.useState(
    undefined as (number | null)[] | undefined
  );
  const [expression, setExpression] = React.useState([] as IElement[]);
  const [visual, setVisual] = React.useState({
    type: IVisualType.EXPRESSION,
  } as IProblemVisualization);
  const dispatchEvent = useEventDispatch();

  const { state } = useLocation();

  useEvent(
    AppLevelEvent.EXPRESSION,
    (event: {
      score: Score;
      expression: IElement[];
      answerLength: number;
      visual: IProblemVisualization;
      delay?: number;
    }) => {
      setCorrectAnswers(undefined);
      setTimeout(() => {
        setVisual(event.visual);
        setExpression(event.expression);
        setTotal(event.score.total);
        setScore(event.score.achieved);
        setId(uniqueId);
        setAnswerLength(event.answerLength);
      }, 500);
    }
  );

  useEvent(AppLevelEvent.SCORE, (event: { score: Score }) => {
    setTimeout(() => {
      setTotal(event.score.total);
      setScore(event.score.achieved);
    }, 100);
  });

  useEvent(AppLevelEvent.MISTAKE, (event: { correctAnswers: number[] }) => {
    setCorrectAnswers(event.correctAnswers);
    setTimeout(() => {
      setCorrectAnswers(undefined);
    }, 1200 * event.correctAnswers.length);
  });

  const onConfirmAnswers = React.useCallback((answers: number[]) => {
    dispatchEvent(AppLevelEvent.ANSWER, answers);
  }, [dispatchEvent]);

  React.useEffect(() => {
    dispatchEvent(AppLevelEvent.INIT, state.props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <LevelComponent
      expression={expression}
      id={id}
      onAnswers={onConfirmAnswers}
      scoreAchieved={score}
      scoreTotal={total}
      visual={visual}
      answerLength={answerLength}
      correctAnswers={correctAnswers}
    ></LevelComponent>
  );
};

export default Level;
