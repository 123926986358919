import React from "react";

import { useEventDispatch } from "@event/EventContext";

import Stars from "@ui/Component/Stars";

import "./SubLevel.css";

type Props = {
  id: string; // nosonar
  eventName: string;
  title: string;
  totalScore?: number;
  score?: number;
  locked: boolean;
  warning?: boolean;
  icon?: string;
};

const SubLevel = (props: Props) => {
  const dispatchEvent = useEventDispatch();
  const [shake, setShake] = React.useState(false);

  const onClickHandler = () => {
    if (!props.locked) {
      dispatchEvent(props.eventName, props);
    } else {
      setShake(true);
      setTimeout(() => {
        setShake(false);
      }, 1000);
    }
  };

  return (
    <div
      className={
        "sublevel" +
        (props.warning ? " warning" : "") +
        (props.locked ? " disabled" : "") +
        (!props.locked && !props.score ? " unlocked" : "")
      }
    >
      <button onClick={onClickHandler}>
        <div className={"icon " + (props.icon ?? "")}></div>
        <div className="content">
          <div className="subject">{props.title}</div>
          {props.locked ? (
            <div className={"lock-icon" + (shake ? " shake" : "" )}></div>
          ) : (
            <Stars
              small={true}
              total={props.totalScore ?? 3}
              achieved={props.score ?? 0}
            ></Stars>
          )}
        </div>
      </button>
    </div>
  );
};

export default SubLevel;
