
type Props = {
  achieved: number;
}

export class LevelScore {

  public static readonly NONE = 0;
  public static readonly MIN = 1;
  public static readonly MIDDLE = 2;
  public static readonly MAX = 3;

  protected achievedScore: number;

  protected constructor(props: Props) {
    this.achievedScore = props.achieved;
  }

  get achieved() : number {
    return this.achievedScore;
  }

  get total() : number {
    return LevelScore.MAX;
  }

  public setMin(): void {
    if (this.achievedScore < LevelScore.MIN) {
      this.achievedScore = LevelScore.MIN;
    }
  }

  public setMax(): void {
    if (this.achievedScore < LevelScore.MAX) {
      this.achievedScore = LevelScore.MAX;
    }
  }

  public setMiddle(): void {
    if (this.achievedScore < LevelScore.MIDDLE) {
      this.achievedScore = LevelScore.MIDDLE;
    }
  }

  get isMax(): boolean {
    return this.achievedScore === LevelScore.MAX;
  }

  get isMiddle(): boolean {
    return this.achievedScore === LevelScore.MIDDLE;
  }

  get isMin(): boolean {
    return this.achievedScore === LevelScore.MIN;
  }

  get isAtLeastMin(): boolean {
    return this.achievedScore >= LevelScore.MIN;
  }

  get isAtLeastMiddle(): boolean {
    return this.achievedScore >= LevelScore.MIDDLE;
  }

  get isNone(): boolean {
    return this.achievedScore === 0;
  }

  public reset(): void {
    this.achievedScore = 0;
  }

  public static create(props: Props): LevelScore {
    if (props.achieved < LevelScore.NONE) {
      throw Error("LevelScore::achieved must be no less than " + LevelScore.NONE);
    }
    if (props.achieved > LevelScore.MAX) {
      throw Error("LevelScore::achieved must be no greater than " + LevelScore.MAX);
    }
    return new LevelScore(props);
  }

}
