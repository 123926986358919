import { Config } from "@app/config/Type";

import { Grade_I_Skills } from "@app/config/skills";

const config: Config = {
  version: "3.0.0",
  grades: {
    "1": {
        age: 6,
        view: {
        icon: "grade-1",
      },
      skills: Grade_I_Skills,
    },
    "2": {
      age: 7,
      view: {
        icon: "grade-2",
      },
      skills: Grade_I_Skills,
    },
  },
};

export default config;
