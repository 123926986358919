import React from "react";

import "./CompositeAnswer.css";
import Answer from "./Answer";

type Props = {
  id: string;
  index: number;
  size: number;
  hint?: boolean;
  answer?: number;
  autoFocus?: boolean;
  onValue: (index: number, value?: number) => void;
};

const CompositeAnswer = (props: Props) => {
  const answers = React.useRef(
    new Array(props.size).fill(undefined) as (number | undefined)[]
  );

  const onValue = (index: number, digit?: number) => {
    const current = answers.current;

    current[props.size - index - 1] = digit;
    answers.current = current;

    const intermediate = answers.current
      .map((value) => {
        return value ?? "~";
      })
      .join("")
      .replace(/^~*/g, "");

    props.onValue(
      props.index,
      RegExp(/^\d+$/).exec(intermediate) ? parseInt(intermediate) : undefined
    );
  };

  const getList = () => {
    const answers = [];
    for (let index = 0; index < props.size; index++) {
      const answerDigit = props.answer
        ? (props.answer?.toString()[index] ? parseInt(props.answer?.toString()[index]) : null)
        : undefined;

      answers.push(
        <Answer
          key={props.id + "_answer_" + index}
          id={props.id + "_answer_" + index}
          index={index}
          autoFocus={index === 0 && (props.autoFocus ?? true)}
          size={1}
          onValue={onValue}
          answer={answerDigit}
          hint={props.hint}
        ></Answer>
      );
    }
    return answers;
  };

  return (
    <div className={"answer-composite" + " size_" + (props.size ?? 1)}>
      {getList()}
    </div>
  );
};

export default CompositeAnswer;
