import { Levels, FixedProblem } from "@app/config/Type";
import {
  LevelPolicySequenceAnswerCorrect,
  LevelPolicySequenceWrongAnswer,
} from "@core/Entity/Level";

/**
 * @description
 * Числата от 11 до 20. Събиране и изваждане на числата до 20 без преминаване.
 */
const config: Levels = {
  "1-1000-1-1": {
    view: {
      title: "11!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "60+10=?", answers: [70] }),
          FixedProblem({ expression: "70+?=80", answers: [10] }),
          FixedProblem({ expression: "80-40=?", answers: [40] }),
          FixedProblem({ expression: "40-?=10", answers: [30] }),
          FixedProblem({ expression: "30+30=?", answers: [60] }),
        ],
      },
    },
  },
  "1-1000-1-2": {
    view: {
      title: "12!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "50+?=60", answers: [10] }),
          FixedProblem({ expression: "60+10=?", answers: [70] }),
          FixedProblem({ expression: "70-20=?", answers: [50] }),
          FixedProblem({ expression: "70+10=?", answers: [80] }),
          FixedProblem({ expression: "30+?=50", answers: [20] }),
        ],
      },
    },
  },
  "1-1000-1-3": {
    view: {
      title: "13!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "90-10=?", answers: [80] }),
          FixedProblem({ expression: "70+20=?", answers: [90] }),
          FixedProblem({ expression: "80-10=?", answers: [70] }),
          FixedProblem({ expression: "80+10=?", answers: [90] }),
          FixedProblem({ expression: "70-10=?", answers: [60] }),
        ],
      },
    },
  },
  "1-1000-1-4": {
    view: {
      title: "13!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "70+20=?", answers: [90] }),
          FixedProblem({ expression: "80+?=90", answers: [10] }),
          FixedProblem({ expression: "60+20=?", answers: [80] }),
          FixedProblem({ expression: "80-10=?", answers: [70] }),
          FixedProblem({ expression: "60-10=?", answers: [50] }),
        ],
      },
    },
  },
  "1-1000-1-5": {
    view: {
      title: "13!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "40+?=70", answers: [30] }),
          FixedProblem({ expression: "40+40=?", answers: [80] }),
          FixedProblem({ expression: "70-10=?", answers: [60] }),
          FixedProblem({ expression: "60+20=?", answers: [80] }),
          FixedProblem({ expression: "60+30=?", answers: [90] }),
        ],
      },
    },
  },
};

export default config;
