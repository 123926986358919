class Storage {

  private namespace = 'kids';

  public reset() {
    localStorage.setItem(this.namespace, JSON.stringify({}));
  }

  public set(partialData: { [key: string]: any }) {
    const saveData = this.get();
    localStorage.setItem(this.namespace, JSON.stringify(Object.assign(saveData, partialData)));
  }

  public get(): { [key: string]: any } {
    try {
      const data = localStorage.getItem(this.namespace);
      return JSON.parse(String(data)) || {};
    } catch (_error) {
      return {};
    }
  }
}

const storage = new Storage();
export default storage;
