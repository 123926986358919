import { Levels, FixedProblem, FixedProblemMemory } from "@app/config/Type";
import { LevelPolicySequenceAnswerCorrect, LevelPolicySequenceWrongAnswer } from "@core/Entity/Level";

/**
 * @description
 * Числата от 11 до 20. Събиране и изваждане на числата до 20 без преминаване.
 */
const config: Levels = {
  "1-400-1-1": {
    view: {
      title: "10+",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "10+1=?", answers: [11] }),
          FixedProblem({ expression: "10+3=?", answers: [13] }),
          FixedProblem({ expression: "3+10=?", answers: [13] }),
          FixedProblem({ expression: "1+10=?", answers: [11] }),
          FixedProblem({ expression: "11+0=?", answers: [11] }),
          FixedProblem({ expression: "10+2=?", answers: [12] }),
        ],
      },
    },
  },
  "1-400-1-2": {
    view: {
      title: "10++",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "10+5=?", answers: [15] }),
          FixedProblem({ expression: "10+3=?", answers: [13] }),
          FixedProblem({ expression: "5+10=?", answers: [15] }),
          FixedProblem({ expression: "4+10=?", answers: [14] }),
          FixedProblem({ expression: "6+10=?", answers: [16] }),
          FixedProblem({ expression: "10+6=?", answers: [16] }),
        ],
      },
    },
  },
  "1-400-1-3": {
    view: {
      title: "10++",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "10+7=?", answers: [17] }),
          FixedProblem({ expression: "10+8=?", answers: [18] }),
          FixedProblem({ expression: "10+9=?", answers: [19] }),
          FixedProblem({ expression: "8+10=?", answers: [18] }),
          FixedProblem({ expression: "7+10=?", answers: [17] }),
          FixedProblem({ expression: "9+10=?", answers: [19] }),
        ],
      },
    },
  },
  "1-400-2-1": {
    view: {
      title: "10+-",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "10+1=?", answers: [11] }),
          FixedProblem({ expression: "11-1=?", answers: [10] }),
          FixedProblem({ expression: "10+2=?", answers: [12] }),
          FixedProblem({ expression: "12-2=?", answers: [10] }),
          FixedProblem({ expression: "12-1=?", answers: [11] }),
          FixedProblem({ expression: "10+?=11", answers: [1] }),
          FixedProblem({ expression: "10+?=12", answers: [2] }),
        ],
      },
    },
  },
  "1-400-2-2": {
    view: {
      title: "10+-",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "10+1=?", answers: [11] }),
          FixedProblem({ expression: "11-1=?", answers: [10] }),
          FixedProblem({ expression: "10+2=?", answers: [12] }),
          FixedProblem({ expression: "12-2=?", answers: [10] }),
          FixedProblem({ expression: "12-1=?", answers: [11] }),
          FixedProblem({ expression: "10+?=11", answers: [1] }),
          FixedProblem({ expression: "10+?=12", answers: [2] }),
        ],
      },
    },
  },
  "1-400-2-3": {
    view: {
      title: "",
      icon: "brain",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: true,
        problems: [
          FixedProblemMemory({ expression: "10+1+1=?", answers: [12] }),
          FixedProblemMemory({ expression: "12-1-1=?", answers: [10] }),
          FixedProblemMemory({ expression: "12-2=?", answers: [10] }),
        ],
      },
    },
  },
};

export default config;
