
type ScoreProps = {
  total: number;
  achieved: number;
}

export class Score {

  protected totalScore: number;
  protected achievedScore: number;

  protected constructor(props: ScoreProps) {
    this.totalScore = props.total;
    this.achievedScore = props.achieved;
  }

  get total(): number {
    return this.totalScore;
  }

  get achieved(): number {
    return this.achievedScore;
  }

  get competed(): boolean {
    return this.achievedScore === this.totalScore;
  }

  public increase(): void {
    if (this.achievedScore <  this.totalScore) {
      this.achievedScore ++;
    }
  }

  public decrease(): void {
    if (this.achievedScore > 0) {
      this.achievedScore --;
    }
  }

  public reset(): void {
    this.achievedScore = 0;
  }

  public static create(props: ScoreProps): Score {
    if ((props.total) < 1) {
      throw Error("Score::total must be greater than zero");
    }
    if (props.achieved < 0) {
      throw Error("Score::achieved must be positive");
    }
    if (props.achieved > (props.total)) {
      throw Error("Score::achieved must be no greater than Score::total");
    }
    return new Score(props);
  }

}
