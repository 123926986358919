import { IAnswer, IElement, IProblem, IProblemVisualization } from "@core/Math/IProblem";
import { ID } from "@core/ValueObject/ID";

type ProblemProps = {
  total: number;
  solved: number;
  problem: IProblem;
};

export class Problem {

  protected props: ProblemProps;

  protected constructor(props: ProblemProps) {
    this.props = props;
  }

  get successRate(): number|undefined {
    return this.props.total 
      ? this.props.solved / this.props.total
      : undefined;
  }

  get id(): ID {
    return this.props.problem.id;
  }

  get problem(): IProblem {
    return this.props.problem;
  }

  get expression(): IElement[] {
    return this.problem.expression;
  }

  get answerLength(): number {
    return this.props.problem.answerLength;
  }

  get visual(): IProblemVisualization {
    return this.props.problem.visual;
  }

  get isSolved(): boolean {
    return this.props.problem.isSolved;
  }

  get correctAnswers(): number[] {
    return this.props.problem.correctAnswers;
  }

  public checkAnswer(answer: IAnswer) {
    this.props.problem.checkAnswer(answer);
    if (this.props.problem.isSolved) {
      this.onSolved();
    } else {
      this.onMistaken();
    }
  }

  public onSolved() {
    this.props.total ++;
    this.props.solved ++;
  }

  public onMistaken() {
    this.props.total ++;
  }

  get total(): number {
    return this.props.total;
  }

  get solved(): number {
    return this.props.solved;
  }

  public static create(props: ProblemProps): Problem {
    if ( props.total < 0) {
      throw Error("Problem::total must be positive");
    }
    if ( props.solved < 0) {
      throw Error("Problem::solved must be positive");
    }
    if ( props.solved > props.total) {
      throw Error("Problem::solved:total mismatch");
    }
    return new Problem(props);
  }

}
