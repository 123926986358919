import { Levels, FixedProblem, FixedProblemMemory } from "@app/config/Type";
import { LevelPolicySequenceAnswerCorrect, LevelPolicySequenceWrongAnswer } from "@core/Entity/Level";

/**
 * @description
 * Естествените числа от 1 до 5 и 0. Събиране и изваждане с числата до 5.
 * Числата от 6 до 9. Събиране и изваждане с числата до 9.
 */
const config: Levels = {
  "1-100-2-1": {
    view: {
      title: "-5",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        length: 10,
        randomize: false,
        problems: [
          FixedProblem({ expression: "5+1=?", answers: [6] }),
          FixedProblem({ expression: "3+4=?", answers: [7] }),
          FixedProblem({ expression: "4+3=?", answers: [7] }),
          FixedProblem({ expression: "3+3=?", answers: [6] }),
          FixedProblem({ expression: "5+?=6", answers: [1] }),
          FixedProblem({ expression: "2+4=?", answers: [6] }),
        ],
      },
    },
  },
  "1-100-2-2": {
    view: {
      title: "4+",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        length: 10,
        randomize: false,
        problems: [
          FixedProblem({ expression: "4+2=?", answers: [6] }),
          FixedProblem({ expression: "4+4=?", answers: [8] }),
          FixedProblem({ expression: "5+3=?", answers: [8] }),
          FixedProblem({ expression: "6+3=?", answers: [9] }),
          FixedProblem({ expression: "5+4=?", answers: [9] }),
          FixedProblem({ expression: "3+?=9", answers: [6] }),
        ],
      },
    },
  },
  "1-100-2-3": {
    view: {
      title: "5+",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        length: 10,
        randomize: false,
        problems: [
          FixedProblem({ expression: "7+2=?", answers: [9] }),
          FixedProblem({ expression: "9-?=7", answers: [2] }),
          FixedProblem({ expression: "5+3=?", answers: [8] }),
          FixedProblem({ expression: "8-3=?", answers: [5] }),
          FixedProblem({ expression: "8-4=?", answers: [4] }),
        ],
      },
    },
  },
  "1-100-2-4": {
    view: {
      title: "6+",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        length: 10,
        randomize: false,
        problems: [
          FixedProblem({ expression: "9-1=?", answers: [8] }),
          FixedProblem({ expression: "8-6=?", answers: [2] }),
          FixedProblem({ expression: "9-5=?", answers: [4] }),
          FixedProblem({ expression: "9-8=?", answers: [1] }),
          FixedProblem({ expression: "8-4=?", answers: [4] }),
          FixedProblem({ expression: "8-5=?", answers: [3] }),
        ],
      },
    },
  },
  "1-100-2-5": {
    view: {
      title: "7+",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        length: 10,
        randomize: false,
        problems: [
          FixedProblem({ expression: "4+3=?", answers: [7] }),
          FixedProblem({ expression: "7-?=4", answers: [3] }),
          FixedProblem({ expression: "6+3=?", answers: [9] }),
          FixedProblem({ expression: "9-?=6", answers: [3] }),
          FixedProblem({ expression: "8-?=4", answers: [4] }),
          FixedProblem({ expression: "8-4=?", answers: [4] }),
        ],
      },
    },
  },
  "1-100-2-6": {
    view: {
      title: "8+",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        length: 10,
        randomize: true,
        problems: [
          FixedProblem({ expression: "7+1=?", answers: [8] }),
          FixedProblem({ expression: "6+?=8", answers: [2] }),
          FixedProblem({ expression: "8+1=?", answers: [9] }),
          FixedProblem({ expression: "1+8=?", answers: [9] }),
          FixedProblem({ expression: "8+?=9", answers: [1] }),
          FixedProblem({ expression: "7+?=9", answers: [2] }),
        ],
      },
    },
  },
  "1-100-2-7": {
    view: {
      title: "9",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        length: 10,
        randomize: true,
        problems: [
          FixedProblem({ expression: "6+2=?", answers: [8] }),
          FixedProblem({ expression: "4+4=?", answers: [8] }),
          FixedProblem({ expression: "?+7=8", answers: [1] }),
          FixedProblem({ expression: "1+1=?", answers: [2] }),
          FixedProblem({ expression: "8+1=?", answers: [9] }),
          FixedProblem({ expression: "5+?=8", answers: [3] }),
          FixedProblem({ expression: "1+8=?", answers: [9] }),
          FixedProblem({ expression: "8+?=9", answers: [1] }),
        ],
      },
    },
  },
  "1-100-2-8": {
    view: {
      title: "✌",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        length: 10,
        randomize: true,
        problems: [
          FixedProblem({ expression: "2+2=?", answers: [4] }),
          FixedProblem({ expression: "6+?=8", answers: [2] }),
          FixedProblem({ expression: "7+2=?", answers: [9] }),
          FixedProblem({ expression: "5+2=?", answers: [7] }),
          FixedProblem({ expression: "5+4=?", answers: [9] }),
          FixedProblem({ expression: "3+3=?", answers: [6] }),
          FixedProblem({ expression: "7+1=?", answers: [8] }),
          FixedProblem({ expression: "6+3=?", answers: [9] }),
        ],
      },
    },
  },
  "1-100-2-9": {
    view: {
      title: "⚡",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        length: 10,
        randomize: true,
        problems: [
          FixedProblem({ expression: "2+2=?", answers: [4] }),
          FixedProblem({ expression: "6+?=8", answers: [2] }),
          FixedProblem({ expression: "7+2=?", answers: [9] }),
          FixedProblem({ expression: "5+2=?", answers: [7] }),
          FixedProblem({ expression: "5+4=?", answers: [9] }),
          FixedProblem({ expression: "3+3=?", answers: [6] }),
          FixedProblem({ expression: "7+1=?", answers: [8] }),
          FixedProblem({ expression: "1+3=?", answers: [4] }),
        ],
      },
    },
  },
  "1-100-2-10": {
    view: {
      title: "",
      icon: "brain",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        length: 10,
        randomize: true,
        problems: [
          FixedProblemMemory({ expression: "3+4+1=?", answers: [8], delay: 2000 }),
          FixedProblemMemory({ expression: "2+5+2=?", answers: [9], delay: 2000 }),
          FixedProblemMemory({ expression: "6+1+2=?", answers: [9], delay: 2000 }),
        ],
      },
    },
  },
};

export default config;
