export class ID {

  protected id: string;

  protected constructor(id: string) {
    this.id = id;
  }

  get value(): string {
    return this.id;
  }

  public equals(other: ID|string): boolean {
    if (typeof other === "string") {
      return other === this.id;
    }
    return other.id === this.id;
  }

  public toString(): string {
    return this.id;
  }

  public static create(id: string): ID {
    if (id === "") {
      throw Error("ID must be none empty");
    }
    return new ID(id);
  }

}
