import { Levels, FixedProblem } from "@app/config/Type";
import { LevelPolicySequenceAnswerCorrect, LevelPolicySequenceWrongAnswer } from "@core/Entity/Level";

/**
 * @description
 * Числата от 11 до 20. Събиране и изваждане на числата до 20 без преминаване.
 */
const config: Levels = {
  "1-600-1-1": {
    view: {
      title: "11!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "10+10=?", answers: [20] }),
          FixedProblem({ expression: "10+?=20", answers: [10] }),
          FixedProblem({ expression: "20-10=?", answers: [10] }),
          FixedProblem({ expression: "20+10=?", answers: [30] }),
          FixedProblem({ expression: "20+20=?", answers: [40] }),
        ],
      },
    },
  },
  "1-600-1-2": {
    view: {
      title: "12!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "10+10=?", answers: [20] }),
          FixedProblem({ expression: "20+10=?", answers: [30] }),
          FixedProblem({ expression: "20+20=?", answers: [40] }),
          FixedProblem({ expression: "40+10=?", answers: [50] }),
          FixedProblem({ expression: "50+10=?", answers: [60] }),
        ],
      },
    },
  },
  "1-600-1-3": {
    view: {
      title: "13!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "10+20=?", answers: [30] }),
          FixedProblem({ expression: "20+10=?", answers: [30] }),
          FixedProblem({ expression: "30+10=?", answers: [40] }),
          FixedProblem({ expression: "10+40=?", answers: [50] }),
          FixedProblem({ expression: "10+50=?", answers: [60] }),
        ],
      },
    },
  },
  "1-600-1-4": {
    view: {
      title: "13!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "20+10=?", answers: [30] }),
          FixedProblem({ expression: "10+30=?", answers: [40] }),
          FixedProblem({ expression: "20+20=?", answers: [40] }),
          FixedProblem({ expression: "30+30=?", answers: [60] }),
          FixedProblem({ expression: "20+30=?", answers: [50] }),
        ],
      },
    },
  },
  "1-600-1-5": {
    view: {
      title: "13!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "10+20=?", answers: [30] }),
          FixedProblem({ expression: "30+10=?", answers: [40] }),
          FixedProblem({ expression: "10+10=?", answers: [20] }),
          FixedProblem({ expression: "20+10=?", answers: [30] }),
          FixedProblem({ expression: "10+30=?", answers: [40] }),
        ],
      },
    },
  },
};

export default config;
