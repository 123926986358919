import React from "react";
import { useLocation } from "react-router-dom";

import { AppSkillsEvent } from "@event/Event";
import { useEvent, useEventDispatch } from "@event/EventContext";

import { Skill as SkillDTO } from "@app/DTO/UI/Skill";
import { default as SkillsComponent } from "@ui/Component/Levels";

const Skills = () => {
  const [skills, setSkills] = React.useState<SkillDTO[]>([]);
  const dispatchEvent = useEventDispatch();

  const { state } = useLocation();

  useEvent(AppSkillsEvent.DATA, (event: { skills: SkillDTO[] }) => {
    setSkills(event.skills);
  });

  React.useEffect(() => {
    dispatchEvent(AppSkillsEvent.INIT, state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className="skills-screen">
      <SkillsComponent levels={skills} eventName={AppSkillsEvent.SELECT} />
    </div>
  );
};

export default Skills;
