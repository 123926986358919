import { Levels, FixedProblem, FixedProblemMemory } from "@app/config/Type";
import { LevelPolicySequenceAnswerCorrect, LevelPolicySequenceWrongAnswer } from "@core/Entity/Level";

/**
 * @description
 * Естествените числа от 1 до 5 и 0. Събиране и изваждане с числата до 5.
 * Числата от 6 до 9. Събиране и изваждане с числата до 9.
 * Числата от 1 до 10. Събиране и изваждане с числата до 10.
 */
const config: Levels = {
  "1-300-1-1": {
    view: {
      title: "10!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "5+2=?", answers: [7] }),
          FixedProblem({ expression: "1+9=?", answers: [10] }),
          FixedProblem({ expression: "9+1=?", answers: [10] }),
          FixedProblem({ expression: "3+6=?", answers: [9] }),
          FixedProblem({ expression: "3+7=?", answers: [10] }),
          FixedProblem({ expression: "5+5=?", answers: [10] }),
        ],
      },
    },
  },
  "1-300-1-2": {
    view: {
      title: "10!!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "5+5=?", answers: [10] }),
          FixedProblem({ expression: "1+9=?", answers: [10] }),
          FixedProblem({ expression: "6+3=?", answers: [9] }),
          FixedProblem({ expression: "6+4=?", answers: [10] }),
          FixedProblem({ expression: "9+1=?", answers: [10] }),
          FixedProblem({ expression: "9+0=?", answers: [9] }),
        ],
      },
    },
  },
  "1-300-1-3": {
    view: {
      title: "0+10",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "6+4=?", answers: [10] }),
          FixedProblem({ expression: "0+10=?", answers: [10] }),
          FixedProblem({ expression: "?+5=10", answers: [5] }),
          FixedProblem({ expression: "6+?=10", answers: [4] }),
          FixedProblem({ expression: "9+1=?", answers: [10] }),
          FixedProblem({ expression: "10+?=10", answers: [0] }),
        ],
      },
    },
  },
  "1-300-1-4": {
    view: {
      title: "?",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "6+4=?", answers: [10] }),
          FixedProblem({ expression: "0+10=?", answers: [10] }),
          FixedProblem({ expression: "?+5=10", answers: [5] }),
          FixedProblem({ expression: "6+?=10", answers: [4] }),
          FixedProblem({ expression: "9+1=?", answers: [10] }),
          FixedProblem({ expression: "10+?=10", answers: [0] }),
        ],
      },
    },
  },
  "1-300-2-1": {
    view: {
      title: "1,2",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "2+8=?", answers: [10] }),
          FixedProblem({ expression: "10-2=?", answers: [8] }),
          FixedProblem({ expression: "10-8=?", answers: [2] }),
          FixedProblem({ expression: "9+1=?", answers: [10] }),
          FixedProblem({ expression: "10-1=?", answers: [9] }),
          FixedProblem({ expression: "10-9=?", answers: [1] }),
        ],
      },
    },
  },
  "1-300-2-2": {
    view: {
      title: "3,4",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "3+7=?", answers: [10] }),
          FixedProblem({ expression: "10-3=?", answers: [7] }),
          FixedProblem({ expression: "10-7=?", answers: [3] }),
          FixedProblem({ expression: "4+6=?", answers: [10] }),
          FixedProblem({ expression: "10-4=?", answers: [6] }),
          FixedProblem({ expression: "10-6=?", answers: [4] }),
        ],
      },
    },
  },
  "1-300-2-3": {
    view: {
      title: "5!",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "5+5=?", answers: [10] }),
          FixedProblem({ expression: "10-5=?", answers: [5] }),
          FixedProblem({ expression: "?-5=5", answers: [10] }),
          FixedProblem({ expression: "10-4=?", answers: [6] }),
          FixedProblem({ expression: "10-?=5", answers: [5] }),
        ],
      },
    },
  },
  "1-300-3-1": {
    view: {
      title: "✌",
      icon: "level",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: false,
        problems: [
          FixedProblem({ expression: "2+8=?", answers: [10] }),
          FixedProblem({ expression: "10-3=?", answers: [7] }),
          FixedProblem({ expression: "10-8=?", answers: [2] }),
          FixedProblem({ expression: "5+5=?", answers: [10] }),
          FixedProblem({ expression: "10-6=?", answers: [4] }),
          FixedProblem({ expression: "10-1=?", answers: [9] }),
        ],
      },
    },
  },
  "1-300-3-2": {
    view: {
      title: "",
      icon: "brain",
    },
    model: {
      policy: {
        sequence: {
          wrongAnswer: LevelPolicySequenceWrongAnswer.REPEAT,
          answerCorrecting: LevelPolicySequenceAnswerCorrect.SHOW,
        },
      },
      sequence: {
        randomize: true,
        problems: [
          FixedProblemMemory({ expression: "1+1+8=?", answers: [10] }),
          FixedProblemMemory({ expression: "10-2-1=?", answers: [7] }),
          FixedProblemMemory({ expression: "10-6-2=?", answers: [2] }),
          FixedProblemMemory({ expression: "5+4+1=?", answers: [10] }),
          FixedProblemMemory({ expression: "10-4-2=?", answers: [4] }),
          FixedProblemMemory({ expression: "10-1=?", answers: [9] }),
        ],
      },
    },
  },
};

export default config;
